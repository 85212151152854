import React from 'react';
import { Routes, Route, BrowserRouter, useLocation, Navigate } from "react-router-dom"
import { connect } from "react-redux"
import LoadPage from './pages/LoadPage/LoadPage'
import NewReservation from './pages/NewReservationPage/NewReservation'
import Reservations from './pages/ReservationsPage/Reservations'
import Information from './pages/InformationPage/Information'
import NewVisitor from './pages/NewVisitorPage/NewVisitor'
import Visitors from './pages/VisitorsPage/Visitors'
import Settings from './pages/SettingsPage/Settings'
import NotFound from './pages/NotFoundPage/NotFound'
import HealtForm from './pages/HealtFormPage/HealtForm'
import Support from './pages/SupportPage/Support'
import Logout from './pages/LogoutPage/Logout'
import Login from './pages/LoginPage/Login'
import Help from './pages/HelpPage/Help'
import QR from './pages/QRPage/QR'
import GeoPage from './pages/GeoPage/Geo'
import Broken from './pages/BrokenPage/Broken'

import { clearTokens } from './api/api'

function Auth({ children, profile }) {
  let location = useLocation()
  if (!profile.email) {
    clearTokens()
    return <Navigate to="/login" state={{ from: location }} />
  }
  return children
}

const mapStateToProps = state => {
  return { profile: state.profile }
}

const RequireAuth = connect(mapStateToProps)(Auth)

function RoutingSystem() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/logout" element={<Logout />} />
        <Route path='/geo' element={<GeoPage />} />
        <Route path="/load" element={<RequireAuth><LoadPage /></RequireAuth>} />
        <Route path="/" element={<RequireAuth><Reservations /></RequireAuth>} />
        <Route path="/visitors" element={<RequireAuth><Visitors /></RequireAuth>} />
        <Route path="/information" element={<RequireAuth><Information /></RequireAuth>} />
        <Route path="/new-reservation" element={<RequireAuth><NewReservation /></RequireAuth>} />
        <Route path="/new-visitor" element={<RequireAuth><NewVisitor /></RequireAuth>} />
        <Route path="/healt-form" element={<RequireAuth><HealtForm /></RequireAuth>} />
        <Route path="/help" element={<RequireAuth><Help /></RequireAuth>} />
        <Route path="/qr" element={<RequireAuth><QR /></RequireAuth>} />
        <Route path="/broken" element={<Broken />} />
        <Route path="/support" element={<Support />} />
        <Route path="/settings" element={<Settings />} />
        <Route path='*' exact={true} element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

export default RoutingSystem
