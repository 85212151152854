import React, { useEffect, useCallback } from 'react'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Types } from '../../../state/actionTypes'
import { PRO_ROOT_URL } from '../../../variables'


function NetworkDetector({ dispatch }) {
  const { t } = useTranslation()
  const setIsOnline = useCallback(() => {
    dispatch({ type: Types.SET_IS_ONLINE, payload: true })
    dispatch({
      type: Types.SET_SNACKBAR_DATA,
      payload: {
        open: true,
        message: t('online'),
        severity: 'success',
        autoHideDuration: 2000
      }
    })

  }, [dispatch, t])

  const setIsOffline = useCallback(() => {
    dispatch({ type: Types.SET_IS_ONLINE, payload: false })
    dispatch({
      type: Types.SET_SNACKBAR_DATA,
      payload: {
        open: true,
        message: t('offline'),
        autoHideDuration: null,
        severity: 'error'
      }
    })
  }, [dispatch, t])

  const handleConnectionChange = useCallback(() => {
    if (navigator.onLine) {
      const webPing = setInterval(
        () => {
          fetch(PRO_ROOT_URL, { mode: 'no-cors' })
            .then(() => {
              setIsOnline()
              clearInterval(webPing)
            }).catch(setIsOffline)
        }, 2000)
      return
    }

    setIsOffline()
  }, [setIsOffline, setIsOnline])


  useEffect(() => {
    window.addEventListener('online', handleConnectionChange)
    window.addEventListener('offline', handleConnectionChange)

    return () => {
      window.removeEventListener('online', handleConnectionChange)
      window.removeEventListener('offline', handleConnectionChange)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <></>
}


export default connect(null)(NetworkDetector)
