import axios from 'axios'
import {
  MAX_401_ATTEMPTS,
  DEV_ROOT_URL,
  PRO_ROOT_URL,
} from '../variables'
const DEFAULT_DOMAIN = 'public'

const clearDomain = domain => {
  const _domain = domain ||
    localStorage.getItem('domain') ||
    DEFAULT_DOMAIN

  if (
    _domain === 'undefined' ||
    _domain === DEFAULT_DOMAIN
  ) return ''
  return `${_domain}.`
}

const getRoot = () => {
  return process.env.REACT_APP_STAGE === 'production' ?
    PRO_ROOT_URL :
    DEV_ROOT_URL
}

const getUrl = domain => `https://${clearDomain(domain)}${getRoot()}`
const getRootUrl = () => `https://${getRoot()}/api`
const getBaseUrl = domain => `${getUrl(domain)}/api`;

const api = axios.create()

api.interceptors.request.use(
  config => {
    let sessionTokens = localStorage.getItem('tokens') || '[]'
    sessionTokens = JSON.parse(sessionTokens)

    if (sessionTokens.length > 0) {
      const lastTokens = sessionTokens[sessionTokens.length - 1]
      config.headers['access-token'] = lastTokens['access-token']
      config.headers['token-type'] = lastTokens['token-type']
      config.headers['client'] = lastTokens['client']
      config.headers['uid'] = lastTokens['uid']
    }
    config.baseURL = getBaseUrl();

    return config;
  }
);

const doneHandler = response => {
  if (response?.headers['access-token']) {
    setTokens(response.headers)
  }

  return response;
}

const errorHandler = error => {
  return Promise.reject(error);
}

api.interceptors.response.use(doneHandler, errorHandler);


const setTokens = tokens => {
  let sessionTokens = localStorage.getItem('tokens') || '[]'
  sessionTokens = JSON.parse(sessionTokens)

  if (sessionTokens.length > MAX_401_ATTEMPTS) sessionTokens.shift()

  sessionTokens.push({
    'access-token': tokens['access-token'],
    'client': tokens['client'],
    'token-type': tokens['token-type'],
    'uid': tokens['uid']
  })
  localStorage.setItem('tokens', JSON.stringify(sessionTokens))
}

const clearTokens = () => {
  localStorage.setItem('tokens', '[]')
  localStorage.setItem('domain', '')
}

const popTokens = () => {
  let sessionTokens = localStorage.getItem('tokens') || '[]'
  sessionTokens = JSON.parse(sessionTokens)
  sessionTokens.pop()
  localStorage.setItem('tokens', JSON.stringify(sessionTokens))
}

const isAuth = () => {
  return JSON.parse(localStorage.getItem('tokens') || '[]').length > 0
}

export {
  api,
  getRoot,
  getRootUrl,
  getUrl,
  getBaseUrl,
  setTokens,
  clearTokens,
  isAuth,
  popTokens
}

