import BrokenComponent from '../../common/components/BrokenComponent/BrokenComponent'

function Broken() {
  return (
    <h1 className='center-message'>
      Broken
      <BrokenComponent />
    </h1>
  )
}

export default Broken