import React, {
  useCallback,
  useEffect,
  useState,
  useRef
} from 'react';

import { connect } from 'react-redux';

import {
  setLoading,
  defaultSuccess,
  defaultCatch,
  _dispatch as dispatch,
} from '../../../state/actions'

import {
  Card,
  CardContent,
  CardMedia,
  Stack,
  Button,
  Chip,
  Box,
  Badge,
  Skeleton
} from '@mui/material';

import {
  Add as AddIcon,
  Remove as RemoveIcon
} from '@mui/icons-material';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { api } from '../../../api/api';
import { Types } from '../../../state/actionTypes'
import './DishCard.sass';

import DishDetailModal from '../DishDetailModal/DishDetailModal';

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

const imageCache = {};

const DishCard = (props) => {
  const {
    dispatch,
    dish,
    setLoading,
    defaultSuccess,
    defaultCatch,
    onOrderQuantityChange,
    onOrderPriceChange,
    setSelectedDishes,
    orderQuantity,
    setOrderQuantity,
    selectedDishes,
		onCloseDishSelectionModal,
    scheduleDayTime,
    currency
  } = props;

  const { t } = useTranslation()
  const navigate = useNavigate()

  const [dishImage, setDishImage] = useState(dish.image);
  const [finalPrice, setFinalPrice] = useState(0);
  const [openDishDetailModal, setOpenDishDetailModal] = useState(false);

  const orderMinQty = 0;
  const orderMaxQty = 999;
  const priceAfterTaxes = dish?.price + dish?.taxes;
  const prevOrderQuantity = usePrevious(orderQuantity);

  useEffect(() => {
    if (prevOrderQuantity !== undefined) {
      onOrderQuantityChange(prevOrderQuantity, orderQuantity);
    }
  }, [orderQuantity, onOrderQuantityChange, prevOrderQuantity]);

  useEffect(() => {
    setFinalPrice(orderQuantity * priceAfterTaxes);
  }, [orderQuantity, priceAfterTaxes]);

  const prevFinalPrice = usePrevious(finalPrice);

  useEffect(() => {
    if (prevFinalPrice !== undefined) {
      onOrderPriceChange(prevFinalPrice, finalPrice);
    }
  }, [finalPrice, onOrderPriceChange, prevFinalPrice]);

  useEffect(() => {
    const fetchDishImage = async () => {
      if (dish.image) return;
  
      const url = dish?.url.replace(/^\/api/, '');
      if (!url) {
        setDishImage();
        return;
      }
  
      if (imageCache[dish.id]) {
        dish.image = imageCache[dish.id];
        setDishImage(imageCache[dish.id]);
        return;
      }
  
      try {
        setLoading(true);
        const { data } = await api.get(url, { responseType: "blob" });
        const image = URL.createObjectURL(data);
        dish.image = image;
        setDishImage(image);
        imageCache[dish.id] = image;
        setLoading(false);
      } catch (error) {
        defaultCatch(error, navigate, fetchDishImage);
        setLoading(false);
      }
    };
  
    fetchDishImage();
  }, [dish, navigate, setDishImage, setLoading, defaultSuccess, defaultCatch]);

  useEffect(() => {
    setOrderQuantity(selectedDishes.filter(d => d.id === dish.id).length)
  }, [dish, selectedDishes, setOrderQuantity]);

  const getMealTypeChip = useCallback(() => {
    const getCategoryLabel = (categoryNumber) => {
      return t(`Meal Categories.${categoryNumber}`);
    }

    return dish?.meal_categories?.map(category =>
      <Chip
        key={category}
        label={getCategoryLabel(category)}
        fontSize="small"
        className='meal-category-chip'
      />
    );
  }, [dish, t]);

  const handleIncrementOrderQty = () => {
    setOrderQuantity((prevCount) => {
      const newValue = Math.min(orderMaxQty, prevCount + 1);
  
      setSelectedDishes((prevDishes) => {
        
        const isDifferentSchedule = prevDishes.some(dish => dish.schedule_day_time !== scheduleDayTime);

        if (!isDifferentSchedule) {
          return [...prevDishes, { id: dish.id, name: dish.name, final_price: dish.price + dish.taxes, schedule_day_time: scheduleDayTime}];
        } else {
          dispatch({
            type: Types.SET_SNACKBAR_DATA,
            payload: {
              open: true,
              message: t('Sorry, it is not possible to order dishes from different meal times in the same order.'),
              severity: 'error',
              vertical: 'top',
              horizontal: 'right',
            }
          })
          return prevDishes
        }

      });
  
      return newValue;
    });
  };

  const handleDecrementOrderQty = () => {
    setOrderQuantity((prevCount) => {
      const newValue = Math.max(orderMinQty, prevCount - 1);
      setSelectedDishes((prevDishes) => {
        const index = prevDishes.findIndex(d => d.id === dish.id);
        if (index !== -1) {
          return [...prevDishes.slice(0, index), ...prevDishes.slice(index + 1)];
        }
        return prevDishes;
      });
      return newValue;
    });
  };

  const onOpenDishDetailModal = () => {
    setOpenDishDetailModal(true);
  }

  const onCloseDishDetailModal = useCallback(() => {
    setOpenDishDetailModal(false);
  }, []);

	const handleChangeCounter = useCallback((value) => {
		setOrderQuantity(value);
	}, [setOrderQuantity]);

	const getDishDetailModal = useCallback(() => {
		return (
			<DishDetailModal 
				open={openDishDetailModal}
				onClose={onCloseDishDetailModal}
				dishImage={dishImage}
				dishName={dish.name}
				dish={dish}
				dishDescription={dish.description}
				getMealTypeChip={getMealTypeChip}
				priceAfterTaxes={priceAfterTaxes}
				orderMinQty={orderMinQty}
				orderMaxQty={orderMaxQty}
				onChangeCounter={handleChangeCounter}
				onCloseDishSelectionModal={onCloseDishSelectionModal}
				setSelectedDishes={setSelectedDishes}
				selectedDishes={selectedDishes}
				onOrderPriceChange={onOrderPriceChange}
				onOrderQuantityChange={onOrderQuantityChange}
        scheduleDayTime={scheduleDayTime}
        currency={currency}
			/>
		)
	}, [dish, dishImage, getMealTypeChip, onCloseDishDetailModal, openDishDetailModal, orderMaxQty, orderMinQty, priceAfterTaxes, selectedDishes, setSelectedDishes, onOrderPriceChange, onOrderQuantityChange, handleChangeCounter, currency, scheduleDayTime, onCloseDishSelectionModal]);

	return (
		<Box className="dish-card">
			<Badge
				className='order-quantity-badge'
				badgeContent={
          selectedDishes.filter(d => d.id === dish.id && d.schedule_day_time === scheduleDayTime).length
        }
			>
				<Card 
					className="dish-card"
				>
					<Box className='dish-card-image-container'>
						{dishImage ? (
							<CardMedia
							className='dish-card-image'
							component="img"
							image={dishImage && dishImage}
							alt={dish.name}
							/>
						) : (
							<Skeleton 
								variant='rectangular' 
								width={'92px'} 
								height={'105px'}
								style={{borderRadius: '10px'}}
							/>
						)
						}
					</Box>
					<CardContent
						className='dish-card-content'
					>
						<Stack
							direction="column"
							justifyContent="left"
							alignItems="left"
							rowGap={'8px'}
						>
							<h1>{dish.name}</h1>
							<Box
								display={'flex'}
								gap={'6px'}
								flexWrap={'wrap'}
							>
								{getMealTypeChip()}
							</Box>
							<Box
								display={'flex'}
								justifyContent={'left'}
							>
								<Button
									className="see-details"
									onClick={onOpenDishDetailModal}
								>
									{t('See details')}
								</Button>
							</Box>
							<Box 
								display={'flex'}
								justifyContent={'space-between'}
								alignItems={'center'}
							>
								<p>{`${currency} ${priceAfterTaxes}`}</p>
								<Stack
									direction="row"
									justifyContent="center"
									alignItems="center"
									gap={'8px'}
								>
									{selectedDishes.find(d => d.id === dish.id && d.schedule_day_time === scheduleDayTime) &&
										<Button
											className='control-button'
											onClick={handleDecrementOrderQty}
											variant="contained"
											size="small"
											sx={{ minWidth: '32px' }}
										>
											<RemoveIcon fontSize="small" />
										</Button>
									}
									<Button
										className='control-button'
										onClick={handleIncrementOrderQty}
										variant="contained"
										size="small"
										sx={{ minWidth: '32px' }}
									>
										<AddIcon fontSize="small" />
									</Button>
								</Stack>

							</Box>
						</Stack>
					</CardContent>
				</Card>
			</Badge>
			{getDishDetailModal()}
		</Box>
	);
};

const mapDispatchToProps = {
  setLoading,
  defaultSuccess,
  defaultCatch,
  dispatch
}

export default connect(null, mapDispatchToProps)(DishCard);