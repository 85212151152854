import React, { useCallback } from 'react'

import {
  Button,
  IconButton,
  Stack,
  DialogContent,
  useMediaQuery,
  Dialog,
  Divider,
  useTheme,
  FormControl,
  TextField,
  InputLabel,
  Box,
  Typography
} from '@mui/material'

import {
  Backdrop,
  CircularProgress
} from '@mui/material'

import { Arrow } from '../Svgs'

import {
  Close as CloseIcon,
  Delete as DeleteIcon,
  Add as AddIcon
} from '@mui/icons-material'

import './VehiclesModal.sass'

import { useTranslation } from 'react-i18next'
import { isValidLicensePlate } from '../../utilities/utilities'

const defaultFunction = () => { }

function VehiclesModal(props) {
  const {
    open = false,
    setOpen = defaultFunction,
    onSaveVehicles = defaultFunction,
    removeVehicle = defaultFunction,
    addNewVehicle = defaultFunction,
    loading,
    vehicles = []
  } = props

  const theme = useTheme()
  const isLarge = useMediaQuery(theme.breakpoints.up('md'))
  const { t } = useTranslation()

  const getVehicleError = useCallback((value, vehicle) => {
    vehicle.error = false
    vehicle.messageError = ''

    if (!isValidLicensePlate(value)) {
      vehicle.error = true
      vehicle.messageError = t('This is not a valid vehicle license plate')
      return vehicle
    }

    const repeated_plate = vehicles.filter(_v => _v.license_plate === value).length > 0
    if (repeated_plate) {
      vehicle.error = true
      vehicle.messageError = t('You already have a vehicle with that license plate. Enter another.')
    }

    return vehicle
  }, [t, vehicles])

  const onChangeLicensePlate = useCallback((e, vehicle) => {
    const value = e.target.value.toUpperCase()
    vehicle = getVehicleError(value, vehicle)
    props.onChangeLicensePlate(value, vehicle)
  }, [getVehicleError, props])

  const getVehicles = useCallback(() => {
    return <div className="complete-form-wrapper">
      {
        vehicles.filter(v => !v.deleted).map((v, index) => {
          return (
            <div key={index} className="form-vehicles-wrapper">
              <InputLabel shrink htmlFor="vehicle-label">
                {t('vehicle')}
              </InputLabel>
              <FormControl fullWidth>
                <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
                  <TextField
                    value={v.license_plate}
                    name="vehicle-label"
                    onChange={e => onChangeLicensePlate(e, v)}
                    variant="outlined"
                    size="small"
                    fullWidth
                    error={v.error}
                    helperText={v.messageError}
                  />
                  <IconButton
                    aria-label="remove vehicle"
                    color="error"
                    onClick={e => removeVehicle(e, v)}>
                    <DeleteIcon width="6px" />
                  </IconButton>
                </Box>
              </FormControl>
            </div>
          )
        })
      }
    </div>
  }, [onChangeLicensePlate, removeVehicle, t, vehicles])

  const backdrop = useCallback(() => {
    return (
      <Backdrop
        sx={{
          color: '#fff',
          zIndex: theme => theme.zIndex.drawer + 1
        }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    )
  }, [loading])

  const disableSaveChanges = useCallback(() => {
    return (vehicles.some(v => v.error))
  }, [vehicles])

  return (
    <Dialog
      className="VehiclesModal dialog-detail"
      fullScreen={!isLarge}
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="responsive-dialog-title">
      <DialogContent className="dialog-detail-content">
        <h3 className='dialog-title'>
          {!isLarge &&
            <IconButton
              className="arrow-back-button"
              aria-label="back"
              color="primary"
              onClick={() => setOpen(false)}>
              <Arrow className="arrow-back" />
            </IconButton>
          }
          {t('vehicles')}
          {isLarge &&
            <IconButton
              className="close-button"
              aria-label="close"
              onClick={() => setOpen(false)} >
              <CloseIcon />
            </IconButton>
          }
        </h3>
        <Divider className="detail-hr" />
        {getVehicles()}
        <Button
          size="small"
          variant="text"
          className="add-new-vehicle"
          onClick={addNewVehicle}>
          <AddIcon />
          <Typography className="add-vehicle">
            {t('add vehicle')}
          </Typography>
        </Button>
        <Stack spacing={2} className='detail-footer'>
          <Button
            disabled={disableSaveChanges()}
            variant="contained"
            onClick={onSaveVehicles}
          >
            {t('save changes')}
          </Button>
        </Stack>
      </DialogContent>
      {backdrop()}
    </Dialog>
  )
}


export default VehiclesModal