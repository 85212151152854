import React from 'react'
import { withTranslation } from 'react-i18next'
import { setLog, types } from '../../utilities/LogError'
import LayoutError from '../LayoutError/LayoutError'
import ReplayIcon from '@mui/icons-material/Replay'
import IconButton from '@mui/material/IconButton'
import Stack from '@mui/material/Stack'


import './ErrorBoundary.sass'

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error) {
    return { hasError: true }
  }

  componentDidCatch(e, { componentStack }) {
    const params = {
      type: types.GLOBAL_ERROR,
      title: e.toString(),
      stack: componentStack
    }

    setLog(params)
  }


  getErrorMessage = () => {
    const { t } = this.props
    return (
      <LayoutError>
        <div className='ErrorBoundary'>
          <div className="image-box">
            <div className='text-wrapper'>
              <h1>{t('sorry')},</h1>
              <h2>{t('an error occurred')}</h2>
            </div>
            <img
              src="./404_error.svg"
              alt="404_error_logo"
            />
          </div>

          <Stack spacing={1} direction="column" className="refresh-box">
            <div className='button-refresh'>
              <IconButton
                color="primary"
                onClick={() => window.location.reload()}
              >
                <ReplayIcon />
              </IconButton>
            </div>
            <p className='text-refresh'>
              {t('we recommend refreshing the page')}
            </p>
          </Stack>
        </div>
      </LayoutError>
    )
  }

  render() {
    if (this.state.hasError) return this.getErrorMessage()
    return this.props.children
  }
}


export default withTranslation()(ErrorBoundary)
