import * as React from 'react'
import { connect } from "react-redux"
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { AppBar, Toolbar, IconButton, Button, MenuItem } from '@mui/material'
import {
  AccountCircle,
  ArrowDropDown as ArrowDropDownIcon
} from '@mui/icons-material'

import { StyledMenu } from '../../../common/utilities/customMaterialUI'
import { logout } from '../../../state/actions'
import Logo from '../../../common/components/Svgs/Logo'
import './Header.sass'

function Header(props) {

  const {
    logoSrc,
    firstName,
    logout
  } = props

  const [anchorEl, setAnchorEl] = React.useState(null)
  const navigate = useNavigate()
  const { t } = useTranslation()
  const handleMenu = event => setAnchorEl(event.currentTarget)
  const handleClose = () => setAnchorEl(null)

  const handleOption = option => {
    if (option === 'logout') logout(navigate)
    else if (option === 'information') navigate('/information')
    else if (option === 'settings') navigate('/settings')
  }

  return (
    <AppBar position="static" className="header">
      <Toolbar className="header-toolbar">
        <Button variant="text" onClick={() => navigate('/')}>
          <div className='logo-img-wrapper'>
            {logoSrc ?
              <img
                src={logoSrc}
                alt="logo"
                className='logo-img'
              />
              :
              <Logo className='logo-img' />
            }
          </div>
        </Button>
        <div className='ml-auto'>
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-app-bar"
            aria-haspopup="true"
            className="header-options"
            onClick={handleMenu}
            color="inherit"
          >
            <AccountCircle />
            <p className="username">
              {firstName}
            </p>
            <ArrowDropDownIcon />
          </IconButton>
          <StyledMenu
            id="menu-app-bar"
            keepMounted
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={() => handleOption('settings')}>{t('settings')}</MenuItem>
            <MenuItem onClick={() => handleOption('information')}>{t('my information')}</MenuItem>
            <MenuItem onClick={() => handleOption('logout')}>{t('logout')}</MenuItem>
          </StyledMenu>
        </div>
      </Toolbar>
    </AppBar>
  )
}

const mapStateToProps = state => {
  return {
    firstName: state.profile.first_name,
    logoSrc: state.profile.logo
  }
}

const mapDispatchToProps = {
  logout
}


export default connect(mapStateToProps, mapDispatchToProps)(Header)