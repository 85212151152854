import React, {
  useCallback,
  useState,
  useRef,
  useEffect
} from "react";

import { connect } from "react-redux";

import {
  _dispatch as dispatch,
  setLoading,
  defaultSuccess,
  defaultCatch,
} from '../../../state/actions'

import {
  Button,
  FormControl,
  Grid,
  Select,
  TextField,
  useMediaQuery,
  useTheme,
  Radio,
  RadioGroup,
  FormControlLabel,
  Badge,
  MenuItem,
  Box,
  Stack,
  Alert,
  Skeleton
} from "@mui/material";

import {
  ListAlt as ListAltIcon,
  Place as PlaceIcon,
  WatchLater as WatchLaterIcon,
  Event as EventIcon,
  Info as InfoIcon
} from '@mui/icons-material'

import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import PickersDay from '@mui/lab/PickersDay'

import { useTranslation } from 'react-i18next';

import {
  formatDateToMed,
  formatDateToISOShort,
  formatTimeFromJS,
  formatDateMedMed,
  formatDateISOtoAMPM,
  getDiffInMinutesFromNow,
  formatDateToISO,
  formatNoTZTimeFromISOtoAMPM
} from "../../utilities/FormatDate";

import {
  evalSetNotes
} from "../../utilities/utilities";

import MenuItemDefault from "../MenuItemDefault";
import SwipeableCalendar from '../SwipeableCalendar/SwipeableCalendar';
import SwipeableTimePicker from '../SwipeableTimePicker/SwipeableTimePicker';
import SuccessBox from '../SuccessBox/SuccessBox'
import DishSelectionModal from "../DishSelectionModal/DishSelectionModal";

import { DateTime } from 'luxon';
import { api } from "../../../api/api";
import { useNavigate } from "react-router-dom";
import { requestQueue } from '../../utilities/RequestQueue';

import {
  FORM,
  SUCCESS
} from '../../../variables'

import './CafeteriaReservation.sass';

const iconSx = { color: '#8C8CA1', fontSize: 15 }
const today = new Date();

const CafeteriaReservation = (props) => {
  const {
    setLoading,
    defaultSuccess,
    defaultCatch,
    cafeteriaMaxFutureDaysToReserve,
    askForCafeteriaOrderNotes,
    askForCafeteriaOrderTime,
    enabledCafeteriaOrderToGo,
    cafeteriaMinReservationTime,
    loading,
    setMainStepperActiveStep
  } = props;

  const { t } = useTranslation();
  const theme = useTheme();
  const isLarge = useMediaQuery(theme.breakpoints.up('md'));
  const inputDateRef = useRef();
  const inputHourRef = useRef()
  const navigate = useNavigate();

  const [date, setDate] = useState('');
  const [calendarDate, setCalendarDate] = useState(today);
  const [openCalendar, setOpenCalendar] = useState(false);
  const [disableCalendarContinue, setDisableCalendarContinue] = useState(false);
  const [cafeterias, setCafeterias] = useState([]);
  const [selectedCafeteriaId, setSelectedCafeteriaId] = useState('');
  const [openDishSelectionModal, setOpenDishSelectionModal] = useState(false);
  const [mealTimes, setMealTimes] = useState([]);
  const [orderedDishCount, setOrderedDishCount] = useState(0);
  const [totalOrderPrice, setTotalOrderPrice] = useState(0);
  const [selectedDishes, setSelectedDishes] = useState([]);
  const [pickUpTime, setPickUpTime] = useState('');
  const [openTimePicker, setOpenTimePicker] = useState(false)
  const [timePickerValue, setTimePickerValue] = useState(today);
  const [disableTimePickerContinue, setDisableTimePickerContinue] = useState(true);
  const [deliveryType, setDeliveryType] = useState(1);
  const [notes, setNotes] = useState('');
  const [successItem, setSuccessItem] = useState(null);
  const [activeStep, setActiveStep] = useState(FORM);
  const [orderQR, setOrderQR] = useState();
  const [differenceInDays, setDifferenceInDays] = useState(0);
  const [differenceInMinutes, setDifferenceInMinutes] = useState(0);
  const [currency, setCurrency] = useState('$');

  useEffect(() => {
    fetchCafeterias();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const fetchQRImage = useCallback(() => {
    const QR = successItem?.qr.replace(/^\/api/, '');
    if (!QR) {
      console.error('QR code is not defined');
      return;
    }  

    setLoading(true);
    requestQueue.addRequest({
      request: () =>
        api.get(QR, { responseType: "blob" })
          .then(defaultSuccess)
          .then(({ data }) => setOrderQR(URL.createObjectURL(data))),
      resolve: () => setLoading(false),
      reject: (error) => defaultCatch(error, navigate, () => fetchQRImage()),
    })
  }, [defaultCatch, defaultSuccess, navigate, setLoading, successItem])

  useEffect(() => {
    if (successItem) {
      fetchQRImage();
    }
  }, [fetchQRImage, successItem])

  useEffect(() => {
    const difference = Math.ceil((new Date(calendarDate) - new Date(today)) / (1000 * 60 * 60 * 24));
    setDifferenceInDays(difference);
    if (difference > cafeteriaMaxFutureDaysToReserve) {
      setDisableCalendarContinue(true);
    } else {
      setDisableCalendarContinue(false);
    }
  }, [calendarDate, cafeteriaMaxFutureDaysToReserve]);

  useEffect(() => {
   const difference = getDiffInMinutesFromNow(formatTimeFromJS(timePickerValue));
    setDifferenceInMinutes(difference);
    if (difference > -cafeteriaMinReservationTime) {
      setDisableTimePickerContinue(true);
    } else {
      setDisableTimePickerContinue(false);
    }
  }, [timePickerValue, cafeteriaMinReservationTime, calendarDate]);

  const fetchCafeterias = useCallback(() => {
    setLoading(true)

    api.get('/cafeteria/sites')
      .then(defaultSuccess)
      .then(({ data }) => {
        if (!data.success) return
        setCafeterias(data.sites)
      })
      .catch(error => defaultCatch(error, navigate, fetchCafeterias))
  }, [navigate, setLoading, defaultSuccess, defaultCatch]);

  const getFormatedDate = useCallback(() => {
    return date ? formatDateToMed(date) :
      t('Select date')
  }, [date, t]);

  const onChangeDateCafeteria = useCallback(() => {
    setOpenCalendar(true)
  }, []);

  const onChangeCalendarDateCafeteria = useCallback(date => {
    setCalendarDate(date)
  }, []);

  const onContinueCalendar = useCallback(() => {
    setDate(calendarDate)
    setSelectedCafeteriaId('')
    setOpenCalendar(false)
  }, [calendarDate]);

  const onCloseCalendar = useCallback(() => {
    setOpenCalendar(false)
  }, []);


  const getFormatedTime = useCallback(() => {
    return pickUpTime ? formatDateISOtoAMPM(pickUpTime) : t('Select time')
  }, [pickUpTime, t]);

  const onContinueTimePicker = useCallback(() => {
    setPickUpTime(formatDateToISO(timePickerValue))
    setOpenTimePicker(false)
  }, [timePickerValue])

  const badgeOnclick = useCallback(day => {
    if (day < today) return
    setCalendarDate(day)
  }, []);

  const renderDay = useCallback((day, _value, DayComponentProps) => {
    const ISODate = formatDateToISOShort(day)
    const isToday = ISODate === formatDateToISOShort(_value[0]);

    return (
      <Badge
        className={`badge-calendar ${isToday ? 'today' : ''}`}
        key={day.toString()}
        onClick={() => badgeOnclick(day)}
      >
        <PickersDay
          {...DayComponentProps}
        />
      </Badge>
    );
  }, [badgeOnclick])

  const getMaxDateCafeteria = useCallback(() => {
    if (!cafeteriaMaxFutureDaysToReserve) return undefined
    return DateTime
      .local()
      .plus({ days: cafeteriaMaxFutureDaysToReserve })
      .toJSDate()
  }, [cafeteriaMaxFutureDaysToReserve])

  const fetchDishes = useCallback((id, date) => {
    setLoading(true)

    api.get(`/cafeteria/meals/${id}`, { params: { date } })
      .then(defaultSuccess)
      .then(({ data }) => {
        const mealTimesData = data.schedules;
        setMealTimes(mealTimesData);
      })
      .catch(error => defaultCatch(error, navigate, () => fetchDishes(id, date)))
  }, [setLoading, defaultSuccess, defaultCatch, navigate])

  const onSelectCafeteria = useCallback(event => {
    const cafeteriaId = event.target.value
    const cafeteria = cafeterias.find(cafeteria => cafeteria.id === cafeteriaId)
    
    setSelectedCafeteriaId(cafeteriaId)
    setCurrency(cafeteria && cafeteria.currency)
    fetchDishes(cafeteriaId, formatDateToISOShort(calendarDate))
  }, [calendarDate, fetchDishes, cafeterias, setSelectedCafeteriaId, setCurrency])

  const onOpenDishSelectionModal = useCallback(() => {
    setOpenDishSelectionModal(true);
  }, []);

  const onCloseDishSelectionModal = useCallback(() => {
    setOrderedDishCount(0);
    setTotalOrderPrice(0);
    setSelectedDishes([]);
    setOpenDishSelectionModal(false);
  }, []);

  const onChangeDeliveryType = (event) => {
    setDeliveryType(event.target.value);
  }

  const sendCafeteriaOrder = useCallback(() => {
    setLoading(true);
    const mealIdArray = selectedDishes.map(dish => ({ meal_id: dish.id }));
    const payload = {
      site_id: selectedCafeteriaId,
      order_date: formatDateToISOShort(calendarDate),
      order_time: pickUpTime,
      delivery_type: deliveryType,
      notes,
      order_meals_attributes: mealIdArray,
      total: totalOrderPrice
    }

    api.post('/cafeteria/order', payload)
      .then(defaultSuccess)
      .then(({ data }) => {
        setSuccessItem(data.order);
        setActiveStep(SUCCESS);
        setMainStepperActiveStep(2);
      })
      .catch(error => defaultCatch(error, navigate, sendCafeteriaOrder))
  }, [setLoading, selectedDishes, selectedCafeteriaId, calendarDate, pickUpTime, deliveryType, notes, totalOrderPrice, defaultSuccess, defaultCatch, navigate, setMainStepperActiveStep]);

  const renderCalendarMessage = useCallback(() => {
    if (differenceInDays > cafeteriaMaxFutureDaysToReserve) {
      return (
        <Alert severity="error" icon={false} style={{ color: "#CD2A26", }} >
          {t('You are only allowed to reserve [X] days in the future. Select another date.', { x: cafeteriaMaxFutureDaysToReserve })}
        </Alert>
      )
    } 
    return null;
  }, [t, cafeteriaMaxFutureDaysToReserve, differenceInDays]);

  const renderTimePickerMessage = useCallback(() => {
    if (differenceInMinutes > -cafeteriaMinReservationTime) {
      return (
        <Alert severity="error" icon={false} style={{ color: "#CD2A26", margin: "16px 0"}} >
          {t('You must choose a greater time than [X] minutes from the current time to pick up your order.', { x: cafeteriaMinReservationTime })}
        </Alert>
      )
    } 
    return null;
  }, [t, differenceInMinutes, cafeteriaMinReservationTime]);

  const slideCafeteria = useCallback(() => {
    return (
      <>
        <div className="CafeteriaReservation div-reservation">
          <h2>{t('Cafeteria Order')}</h2>
          <div className='form-wrapper workplace'>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Grid container spacing={isLarge ? 8 : 5} alignItems="flex-end">
                <Grid item xs={12} md={7}>
                  <label
                    className={`pre-label-form-mf`}>
                    {t('For which day would you like to order?')}
                  </label>
                  <Grid
                    container
                    spacing={isLarge ? 0 : 5}
                    className="form-input-group"
                  >
                    <Grid item xs={12} md>
                      <FormControl fullWidth>
                        <TextField
                          value={getFormatedDate()}
                          onClick={onChangeDateCafeteria}
                          variant="filled"
                          placeholder={t('Select date')}
                          fullWidth
                          inputRef={inputDateRef}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={7}>
                  <label
                    className={`pre-label-form-mf`}>
                    {t('Which cafeteria would you like to order from?')}
                  </label>
                  <Grid item xs={12} md>
                    <FormControl fullWidth>
                      <Select
                        value={selectedCafeteriaId}
                        onChange={onSelectCafeteria}
                        placeholder={t('Select cafeteria')}
                        variant="filled"
                        fullWidth
                        disabled={!date}
                      >
                        <MenuItemDefault />
                        {cafeterias?.map((c, index) =>
                          <MenuItem
                            key={`${c.id}-${index}`}
                            value={c.id}>
                            {c.name}
                          </MenuItem>
                        )}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={7}>
                  <label
                    className={`pre-label-form-mf`}>
                    {t('Select the dish of your preference')}
                  </label>
                  <Grid item xs={12} md>
                    <FormControl fullWidth>
                      <TextField
                        multiline
                        onClick={onOpenDishSelectionModal}
                        placeholder={t('Select dish')}
                        variant="filled"
                        fullWidth
                        disabled={selectedCafeteriaId === ''}
                        value={selectedDishes.map(dish => `• ${dish.name}`).join('\n')}
                        InputProps={{
                          readOnly: true,
                          style: { whiteSpace: 'pre-line' }
                        }}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                {askForCafeteriaOrderTime &&
                  <Grid item xs={12} md={7}>
                    <label
                      className={`pre-label-form-mf`}>
                      {t('When will you arrive to pick up the dish?')}
                    </label>
                    <Grid item xs={12} md>
                      <FormControl fullWidth>
                        <TextField
                          value={getFormatedTime()}
                          variant="filled"
                          fullWidth
                          onClick={() => setOpenTimePicker(true)}
                          inputRef={inputHourRef}
                          disabled={selectedDishes.length === 0}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                }
                {enabledCafeteriaOrderToGo &&
                  <Grid item xs={12} md={7}>
                    <label
                      className={`pre-label-form-mf`}>
                      {t('Is your order for take away or to eat in the cafeteria?')}
                    </label>
                    <Grid item xs={12} md>
                      <FormControl fullWidth>
                        <RadioGroup
                          value={deliveryType}
                          onChange={onChangeDeliveryType}
                        >
                          <FormControlLabel
                            value={0}
                            control={<Radio disabled={selectedDishes.length === 0} />}
                            label={t('Take away')}
                          />
                          <FormControlLabel
                            value={1}
                            control={<Radio disabled={selectedDishes.length === 0} />}
                            label={t('To eat in the cafeteria')}
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                  </Grid>
                }
                {askForCafeteriaOrderNotes &&
                  <Grid item xs={12} md={7}>
                    <label
                      className={`pre-label-form-mf`}>
                      {t('Write a note')}
                    </label>
                    <Grid
                      container
                      spacing={isLarge ? 0 : 5}
                      className="form-input-group"
                    >
                      <Grid item xs={12} md>
                        <FormControl fullWidth>
                          <TextField
                            value={notes}
                            onChange={e => evalSetNotes(e.target.value, setNotes)}
                            variant="filled"
                            autoComplete="off"
                            rows={3}
                            fullWidth
                            multiline
                            disabled={selectedDishes.length === 0}
                            placeholder={t('Notes (optional)')}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>
                }
              </Grid>
            </LocalizationProvider>
          </div>
          <div className='new-reservation-footer'>
            <Button
              fullWidth={!isLarge}
              variant={(askForCafeteriaOrderTime && !pickUpTime) || selectedDishes.length === 0 ? "outlined" : "contained"}
              disabled={(askForCafeteriaOrderTime && !pickUpTime) || selectedDishes.length === 0}
              onClick={sendCafeteriaOrder}
            >
              {isLarge ?
                t('ACCEPT RESERVATION') :
                t('ACCEPT')
              }
            </Button>
          </div>
        </div>
        <SwipeableCalendar
          maxDate={getMaxDateCafeteria()}
          minDate={today}
          date={calendarDate}
          open={openCalendar}
          onChange={onChangeCalendarDateCafeteria}
          setOpen={setOpenCalendar}
          renderDay={renderDay}
          onContinue={onContinueCalendar}
          onClose={onCloseCalendar}
          renderMessage={renderCalendarMessage}
          isDisabled={disableCalendarContinue}
        />
        <DishSelectionModal
          open={openDishSelectionModal}
          onClose={onCloseDishSelectionModal}
          onContinue={() => setOpenDishSelectionModal(false)}
          mealTimes={mealTimes}
          isLarge={isLarge}
          selectedDishes={selectedDishes}
          orderedDishCount={orderedDishCount}
          totalOrderPrice={totalOrderPrice}
          setSelectedDishes={setSelectedDishes}
          setOrderedDishCount={setOrderedDishCount}
          setTotalOrderPrice={setTotalOrderPrice}
          setDate={setDate}
          setSelectedCafeteriaId={setSelectedCafeteriaId}
          loading={loading}
          currency={currency}
          setCurrency={setCurrency}
        />
        <SwipeableTimePicker
          time={timePickerValue}
          open={openTimePicker}
          setTime={setTimePickerValue}
          setOpen={setOpenTimePicker}
          onContinue={onContinueTimePicker}
          onClose={() => setOpenTimePicker(false)}
          renderMessage={renderTimePickerMessage}
          isDisabled={disableTimePickerContinue}
        />
      </>
    )
  }, [t, isLarge, getFormatedDate, onChangeDateCafeteria, selectedCafeteriaId, onSelectCafeteria, date, cafeterias, onOpenDishSelectionModal, selectedDishes, askForCafeteriaOrderTime, getFormatedTime, enabledCafeteriaOrderToGo, deliveryType, askForCafeteriaOrderNotes, notes, pickUpTime, sendCafeteriaOrder, getMaxDateCafeteria, calendarDate, openCalendar, onChangeCalendarDateCafeteria, renderDay, onContinueCalendar, onCloseCalendar, disableCalendarContinue, openDishSelectionModal, onCloseDishSelectionModal, mealTimes, orderedDishCount, totalOrderPrice, loading, timePickerValue, openTimePicker, onContinueTimePicker, renderCalendarMessage, renderTimePickerMessage, disableTimePickerContinue, currency, setCurrency]);

  const slideSuccess = useCallback(() => {
    return (
      <SuccessBox
        title={t('Your order was successfully placed.')}
        buttonLabel={t('Back to home')}
        download
      >
        <Box
          className="detail-list-wrapper"
          id="ticket-print-area"
        >
          <ul className="detail-list">
            <li>
              {t('Cafeteria')}
            </li>
            <li>
              <EventIcon sx={iconSx} />
              <span>
                {formatDateMedMed(successItem?.order_date)}
              </span>
            </li>
            {askForCafeteriaOrderTime &&
              <li>
                <WatchLaterIcon sx={iconSx} />
                <span>
                  {formatNoTZTimeFromISOtoAMPM(successItem?.order_time)}
                </span>
              </li>
            }
            <li>
              <PlaceIcon sx={iconSx} />
              <span>
                {successItem?.site?.name}
              </span>
            </li>
            <li>
              <ListAltIcon sx={iconSx} />
              <ul className="detail-list">
                {successItem?.meals?.map((meal, index) => {
                  return (
                    <li key={`${meal.id} - ${index}`}>
                      <span>{meal.name}</span>
                    </li>
                  )
                })}
                <li>{`Total: ${currency} ${totalOrderPrice}`}</li>
              </ul>
            </li>

          </ul>
          <Box
            display="flex"
            width={'100%'}
            justifyContent={'center'}
          >
            {orderQR ?
              <img
                src={orderQR}
                alt="Order-QR"
                width={'163px'}
                height={'163px'}
              />
              :
              <Skeleton
                variant='rectangular'
                width={'163px'}
                height={'163px'}
              />
            }
          </Box>
        </Box>
        <Box>
          <Stack
            className="info-qr-text"
            direction='row'
            alignItems='center'
            columnGap={'8px'}
          >
            <InfoIcon sx={iconSx} />
            {t('You can view the QR again in reservation details.')}
          </Stack>
        </Box>
      </SuccessBox>
    )
  }, [t, successItem, askForCafeteriaOrderTime, totalOrderPrice, orderQR, currency])

  const getSlide = useCallback(() => {
    if (activeStep === FORM) return slideCafeteria()
    return slideSuccess()
  }, [activeStep, slideCafeteria, slideSuccess])

  return (
    <div>
      {getSlide()}
    </div>
  )
}

const mapStateToProps = state => {
  return {
    cafeteriaMaxFutureDaysToReserve: state.profile.company.cafeteria_max_future_days_to_reserve,
    askForCafeteriaOrderNotes: state.profile.company.ask_for_cafeteria_order_notes,
    askForCafeteriaOrderTime: state.profile.company.ask_for_cafeteria_order_time,
    enabledCafeteriaOrderToGo: state.profile.company.enabled_cafeteria_order_to_go,
    cafeteriaMinReservationTime: state.profile.company.cafeteria_min_reservation_time,
    loading: state.backdrop.loading
  }
}

const mapDispatchToProps = {
  dispatch,
  setLoading,
  defaultSuccess,
  defaultCatch
}

export default connect(mapStateToProps, mapDispatchToProps)(CafeteriaReservation);