import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import locales from './locales'
const resources = locales
const lng = localStorage.getItem('lng') || 'en'

i18n
  .use(initReactI18next)
  .init({
    interpolation: { escapeValue: false },
    lng,
    resources,
  });

export default i18n