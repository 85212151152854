import React from 'react';
import Tooltip from '@mui/material/Tooltip';

import './HtmlTooltip.sass'


function HtmlTooltip({ children, title, message, renderMessage, ...othersProps }) {
  return (
    <Tooltip
      {...othersProps}
      className="HtmlTooltip"
      title={
        <>
          <h4>{title}</h4>
          {
            renderMessage ?
              renderMessage() :
              <p>{message}</p>
          }
        </>
      }
    >
      {children}
    </Tooltip>
  );
}


export default HtmlTooltip



