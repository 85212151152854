const LocationCircle = props => {
	const {
		className = '',
		style = {},
		width = 32,
		color = "#9B9B9B",
		circleColor = "#EFEFEF"
	} = props

	return (
		<svg
			className={className}
			width={width}
			height={32}
			style={style}
			xmlns="http://www.w3.org/2000/svg"
			fill="none" 
			viewBox="0 0 32 32" 
		>
			<circle cx="12" cy="16" r="12" fill={circleColor}/>
			<path fillRule="evenodd" clipRule="evenodd" d="M12.0007 9.33398C9.42065 9.33398 7.33398 11.4207 7.33398 14.0007C7.33398 17.5007 12.0007 22.6673 12.0007 22.6673C12.0007 22.6673 16.6673 17.5007 16.6673 14.0007C16.6673 11.4207 14.5807 9.33398 12.0007 9.33398ZM12.0007 15.6673C11.0807 15.6673 10.334 14.9207 10.334 14.0007C10.334 13.0807 11.0807 12.334 12.0007 12.334C12.9207 12.334 13.6673 13.0807 13.6673 14.0007C13.6673 14.9207 12.9207 15.6673 12.0007 15.6673Z" fill={color}/>
		</svg>
	)
}

export default LocationCircle
