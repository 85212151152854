import { createStore, combineReducers, compose, applyMiddleware } from 'redux'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import thunk from 'redux-thunk'

import profile from './reducers/profile'
import snackbar from './reducers/snackbar'
import backdrop from './reducers/backdrop'
import general from './reducers/general'
import parking from './reducers/parking'
import workplace from './reducers/workplace'
import setting from './reducers/setting'
import cafeteria from './reducers/cafeteria'

const rootReducer = combineReducers({
  profile,
  snackbar,
  parking,
  workplace,
  setting,
  backdrop,
  general,
  cafeteria
})

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['profile', 'workplace', 'general', 'setting', 'cafeteria']
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const configureStore = () => {
  let store = createStore(persistedReducer, composeEnhancers(applyMiddleware(thunk)))
  let persistor = persistStore(store)
  return { store, persistor }
};

export default configureStore