import React from 'react'
import { Stack } from '@mui/material'
import Grid from '@mui/material/Grid'
import { DateTime } from "luxon"
import { useTranslation } from 'react-i18next'

import {
  IconButton,
  Divider
} from '@mui/material'

import {
  formatTimeFromJSArray
} from '../../utilities/FormatDate'


import './TimePicker.sass'

const hours = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12']
const minutes = ['00', '05', '10', '15', '20', '25', '30', '35', '40', '45', '50', '55']
const today = new Date()
const defaultFunction = () => { }

function TimePicker(props) {
  const { t } = useTranslation()
  const {
    time = today,
    disabled = false,
    setTime = defaultFunction,
    timeArray = time ?
      formatTimeFromJSArray(time) :
      formatTimeFromJSArray(today)
  } = props

  const isPM = () => timeArray[2] === 'pm'
  const getHour24 = hour => isPM() ? Number(hour) + 12 : hour

  const setIsPM = _isPM => {
    if (_isPM === isPM()) return

    const _hour = DateTime.fromJSDate(time).hour
    const hour = _isPM ? _hour - 12 : _hour + 12
    const _time = DateTime.fromJSDate(time).set({ hour }).toJSDate()
    setTime(_time)
  }

  const setHour = hour => {
    const _hour = getHour24(hour)
    const _time = DateTime.fromJSDate(time).set({ hour: _hour }).toJSDate()
    setTime(_time)
  }

  const setMinute = minute => {
    const _time = DateTime.fromJSDate(time).set({ minute }).toJSDate()
    setTime(_time)
  }

  const isActiveHour = hour => {
    return Number(hour) === Number(timeArray[0])
  }

  const isActiveMinute = minute => {
    return Number(minute) === Number(timeArray[1])
  }

  return (
    <div className={`TimePicker ${disabled ? 'disabled' : ''}`}>
      <Stack spacing={2} direction="row" justifyContent="center">
        <IconButton
          disabled={disabled}
          className={!isPM() ? 'am-pm active' : 'am-pm'}
          onClick={() => setIsPM(false)}
          size="small"
        >
          AM
        </IconButton>
        <IconButton
          disabled={disabled}
          className={isPM() ? 'am-pm active' : 'am-pm'}
          onClick={() => setIsPM(true)}
          size="small"
        >
          PM
        </IconButton>
      </Stack>
      <Divider />
      <Grid container spacing={3}>
        <Grid item xs>
          <label>{t('hour')}</label>
          {hours.map((_hour, index) => (
            <IconButton
              key={index}
              disabled={disabled}
              className={`
                item-timePicker
                ${isActiveHour(_hour) ? 'active' : ''}
              `}
              onClick={() => setHour(_hour)}
              size="small"
            >
              {_hour}
            </IconButton>
          ))}
        </Grid>
        <Grid item xs>
          <label>{t('minutes')}</label>
          {minutes.map((_minute, index) => (
            <IconButton
              key={index}
              disabled={disabled}
              className={`
                item-timePicker
                ${isActiveMinute(_minute) ? 'active' : ''}
              `}
              onClick={() => setMinute(_minute)}
              size="small"
            >
              {_minute}
            </IconButton>
          ))}
        </Grid>
      </Grid>
    </div>
  );
}


export default TimePicker
