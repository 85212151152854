import React, {
  useCallback,
  useState,
  useEffect
} from 'react';

import {
  Dialog,
  DialogContent,
  DialogActions,
  Grid,
  Button,
  IconButton,
  Box,
  Stack,
  Tabs,
  Tab,
  DialogTitle
} from '@mui/material';

import {
  Error as ErrorIcon,
  Restaurant as RestaurantIcon,
  Close as CloseIcon,
} from '@mui/icons-material';

import { DateTime } from 'luxon';

import CalendarOutlined from '../Svgs/CalendarOutlined';

import { useTranslation } from 'react-i18next';
import DishCard from '../DishCard/DishCard';
import './DishSelectionModal.sass';

const DishSelectionModal = props => {
  const {
    open,
    onClose,
    mealTimes,
    isLarge,
    orderedDishCount,
    setOrderedDishCount,
    totalOrderPrice,
    setTotalOrderPrice,
    setSelectedDishes,
    selectedDishes,
    onContinue,
    setDate,
    setSelectedCafeteriaId,
    setCurrency,
    loading,
    currency,
  } = props;

  const { t } = useTranslation();

  const [sortedMealTimes, setSortedMealTimes] = useState(mealTimes);
  const [tabValue, setTabValue] = useState('Desayuno');
  const [orderQuantity, setOrderQuantity] = useState(0);


  useEffect(() => {
    if (mealTimes) {
    const _sortedMealTimes = mealTimes?.sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      if (a.start_time < b.start_time) {
        return -1;
      }
      if (a.start_time > b.start_time) {
        return 1;
      }
    
      return 0;
    });
    
      setSortedMealTimes(_sortedMealTimes);
      setTabValue(_sortedMealTimes[0]?.name);
    }
  }, [mealTimes])

  const handleOrderQuantityChange = useCallback(() => {
    setOrderedDishCount(selectedDishes.length);
  }, [selectedDishes.length, setOrderedDishCount]);

  const handleOrderPriceChange = useCallback(() => {
    setTotalOrderPrice(selectedDishes.reduce((acc, dish) => acc + dish.final_price, 0));
  }, [selectedDishes, setTotalOrderPrice]);

  useEffect(() => {
    handleOrderQuantityChange();
    handleOrderPriceChange();
  }, [handleOrderPriceChange, handleOrderQuantityChange, selectedDishes]);

  const handleChangeTab = useCallback((event, newValue) => {
    setTabValue(newValue);
  }, []);

  const getDishes = useCallback(() => {
    const dishesByMealTime = sortedMealTimes?.filter(mealTime => mealTime.name === tabValue)[0]?.meals;

    return (
      dishesByMealTime?.map((meal, index) => {
        return (
          <Grid key={`${meal.name}-${index}`} item xs>
            <DishCard
              dish={meal}
              onOrderQuantityChange={handleOrderQuantityChange}
              onOrderPriceChange={handleOrderPriceChange}
              setSelectedDishes={setSelectedDishes}
              orderQuantity={orderQuantity}
              setOrderQuantity={setOrderQuantity}
              selectedDishes={selectedDishes}
              onCloseDishSelectionModal={onClose}
              scheduleDayTime={tabValue}
              currency={currency}

            />
          </Grid>
        )
      })
    )

  }, [tabValue, handleOrderQuantityChange, handleOrderPriceChange, setSelectedDishes, orderQuantity, selectedDishes, onClose, sortedMealTimes, currency])

  return (
    <Dialog
      className="dialog-detail dish-selection-modal"
      fullScreen={!isLarge}
      maxWidth="md"
      open={open}
      onClose={onClose}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle >
        <Box className='dishes-modal-header'>
          <h2>{t('Select the dish of your preference')}</h2>
          <IconButton
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent className="dialog-detail-content">
        <Stack
          width='100%'
          rowGap={'30px'}
        >
          <Tabs
            value={tabValue}
            onChange={handleChangeTab}
            aria-label="menu tabs"
            variant='scrollable'
            scrollButtons='auto'
            className='tabs-container'
          >
            {sortedMealTimes?.map((mealTime, index) => {
              const mealTimeStart = DateTime.fromISO(mealTime.start_time, { setZone: true }).toFormat('hh:mm a');
              const mealTimeEnd = DateTime.fromISO(mealTime.end_time, { setZone: true }).toFormat('hh:mm a');
              return (
                <Tab
                  key={`${mealTime.name}-${index}`}
                  label={
                    <Stack
                      rowGap={'8px'}
                      color={'black'}
                    >
                      <Box
                        fontSize={'16px'}
                        fontWeight={600}
                      >
                        {mealTime.name}
                      </Box>
                      <Box
                        fontSize={'12px'}
                      >
                        {`${mealTimeStart} - ${mealTimeEnd}`}
                      </Box>
                    </Stack>
                  }
                  value={mealTime.name}
                  className='tab-item'
                />
              )
            })}
          </Tabs>
          <Box
            width={'100%'}
            display={'flex'}
            justifyContent={'center'}
          >
            {!loading && (sortedMealTimes?.length > 0 ?
              <Grid
                container
                direction="row"
                justifyContent="space-around"
                alignItems="center"
                spacing={3}
                className='dishes-cards-container'
              >
                {getDishes()}
              </Grid>
              :
              <Stack
                direction={'column'}
                justifyContent={'center'}
                alignItems={'center'}
                rowGap={'63px'}
                maxWidth={'300px'}
                className='no-dishes'
              >
                <Stack
                  direction={'column'}
                  justifyContent={'center'}
                  alignItems={'center'}
                  rowGap={'24px'}
                >
                  <ErrorIcon className='info-alert' />
                  <h1>{t('Sorry, this cafeteria does not have a set menu.')}</h1>
                </Stack>
                <Stack
                  direction={'column'}
                  justifyContent={'center'}
                  alignItems={'center'}
                  rowGap={'32px'}
                >
                  <Button
                    variant='outlined'
                    onClick={() => {
                      onClose();
                      setDate('');
                      setSelectedCafeteriaId('');
                      setCurrency('');
                    }}
                  >
                    <CalendarOutlined width={'24px'} />
                    {t('Change date')}
                  </Button>
                  <Button
                    variant='outlined'
                    onClick={onClose}
                  >
                    <RestaurantIcon />
                    {t('Change cafeteria')}
                  </Button>
                </Stack>
              </Stack>
            )}
          </Box>
        </Stack>
      </DialogContent>
      {!!totalOrderPrice &&
        <DialogActions className='dishes-modal-footer'>
          <Stack
            direction={'row'}
            width={'100%'}
            justifyContent={'center'}
            columnGap={'16px'}
          >
            <Stack
              direction={'column'}
              rowGap={'8px'}
            >
              <p>{`${orderedDishCount} ${orderedDishCount > 1 ? t('dishes') : t('dish')}`}</p>
              <h1>{`Total: ${currency} ${totalOrderPrice}`}</h1>
            </Stack>
            <Button
              className='dishes-modal-footer-button'
              fullWidth={!isLarge}
              variant="contained"
              onClick={onContinue}
            >
              {t('continue')}
            </Button>
          </Stack>
        </DialogActions>
      }
    </Dialog>
  );
}

export default DishSelectionModal;