import React, { useState, useEffect } from 'react'
import { connect } from "react-redux"
import { useTranslation } from 'react-i18next'
import Layout2 from '../../common/components/LayoutCreate/LayoutCreate'
import { useNavigate } from 'react-router-dom'
import { api } from '../../api/api'
import { Types } from '../../state/actionTypes'

import {
  setLoading,
  defaultCatch,
  defaultSuccess,
  _dispatch as dispatch
} from '../../state/actions'

import {
  Button,
  useMediaQuery,
  useTheme,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Radio,
  RadioGroup,
  Stack
} from '@mui/material'

import {
  CheckCircle as CheckCircleIcon,
  HomeRounded as HomeRoundedIcon,
} from '@mui/icons-material'

import {
  formatDateMed
} from '../../common/utilities/FormatDate'

import '../NewReservationPage/NewReservation.sass'
import './HealtForm.sass'

function HealtForm(props) {
  const {
    dispatch,
    defaultCatch,
    defaultSuccess,
    setLoading,
    companyName
  } = props

  const { t } = useTranslation()
  const navigate = useNavigate()
  const [activeStep, setActiveStep] = useState('form')
  const [symptomAssessment, setSymptomAssessment] = useState({})

  const [fever, setFever] = useState(false)
  const [fatigue, setFatigue] = useState(false)
  const [cough, setCough] = useState(false)
  const [congestion, setCongestion] = useState(false)
  const [bodyache, setBodyache] = useState(false)
  const [sorethroat, setSorethroat] = useState(false)
  const [noSymptoms, setNoSymptoms] = useState(true)
  const [hasContact, setHasContact] = useState(false)

  const theme = useTheme()
  const isLarge = useMediaQuery(theme.breakpoints.up('md'))


  useEffect(() => {
    if (!noSymptoms) return
    setFever(false)
    setFatigue(false)
    setCough(false)
    setCongestion(false)
    setBodyache(false)
    setSorethroat(false)
  }, [noSymptoms])

  useEffect(() => {
    if (
      fever ||
      fatigue ||
      cough ||
      congestion ||
      bodyache ||
      sorethroat
    ) setNoSymptoms(false)

  }, [fever, fatigue, cough, congestion, bodyache, sorethroat])

  const evalHealt = () => noSymptoms && !hasContact

  const saveHealtForm = () => {
    setLoading(true)

    const symptoms = []
    if (fever) symptoms.push(0)
    if (fatigue) symptoms.push(1)
    if (cough) symptoms.push(2)
    if (congestion) symptoms.push(3)
    if (bodyache) symptoms.push(4)
    if (sorethroat) symptoms.push(5)
    if (noSymptoms) symptoms.push(6)

    const payload = {
      symptom_assessment: {
        symptoms,
        has_contact: hasContact
      }
    }

    api.post('/workplace/symptom_assessment', payload)
      .then(defaultSuccess)
      .then(({ data }) => {
        if (!data.success && data.message) {
          dispatch({
            type: Types.SET_SNACKBAR_DATA,
            payload: {
              open: true,
              message: data.message,
              severity: 'error',
              autoHideDuration: 6000
            }
          })
        } else {
          setSymptomAssessment(data.symptom_assessment)
          setActiveStep(evalHealt() ? 'healthy' : 'sick')
        }
      })
      .catch(error => defaultCatch(error, saveHealtForm))
  }

  const getForm = () => {
    return (
      <div className="div-reservation healt-form">
        <div className='form-wrapper workplace'>
          <h2>{t('Check the symptoms you are experiencing')}</h2>
          <FormGroup>
            <FormControlLabel control={<Checkbox checked={fever} onChange={e => setFever(e.target.checked)} />} label={t("Fever")} />
            <FormControlLabel control={<Checkbox checked={fatigue} onChange={e => setFatigue(e.target.checked)} />} label={t("Fatigue")} />
            <FormControlLabel control={<Checkbox checked={cough} onChange={e => setCough(e.target.checked)} />} label={t("Dry cough")} />
            <FormControlLabel control={<Checkbox checked={congestion} onChange={e => setCongestion(e.target.checked)} />} label={t("Nasal congestion")} />
            <FormControlLabel control={<Checkbox checked={bodyache} onChange={e => setBodyache(e.target.checked)} />} label={t("Aches and pains")} />
            <FormControlLabel control={<Checkbox checked={sorethroat} onChange={e => setSorethroat(e.target.checked)} />} label={t("Throat pain")} />
            <FormControlLabel control={<Checkbox checked={noSymptoms} onChange={e => setNoSymptoms(e.target.checked)} />} label={t("No symptoms")} />
          </FormGroup>
          <h2>{t('Have you been in contact or close to someone diagnosed with COVID-19 in the last 14 days?')}</h2>
          <RadioGroup
            onChange={e => setHasContact(e.target.value)}
            value={hasContact}
            name="has-contact">
            <FormControlLabel value={true} control={<Radio />} label={t("Yes")} />
            <FormControlLabel value={false} control={<Radio />} label={t("No")} />
          </RadioGroup>
        </div>
        <div className='new-reservation-footer'>
          <Button
            fullWidth={!isLarge}
            variant="outlined"
            onClick={saveHealtForm} >
            {t('CONTINUE')}
          </Button>
        </div>
      </div>
    )
  }

  const successBox = () => {
    return (
      <div className='Success-reservation'>
        <div className='success-box'>
          <CheckCircleIcon className="success-icon" />
          <h1 className='success-title'>{t('Has access to the office')}</h1>
          <div className="detail-list-wrapper">
            <div className='detail-list'>
              <p>{t('Thank you for completing the daily health check.')}</p>
              <p>{t('All the day')} <strong>{formatDateMed(symptomAssessment.reservable.reservation_date)}</strong> {t('You have permission to enter the offices of x.', { companyName })}</p>
              <p>{t('Important: the permission granted is valid only for the date previously indicated')}</p>
            </div>
          </div>
          <Stack spacing={2} className='success-footer'>
            <Button
              variant="outlined"
              onClick={() => navigate("/")} >
              {t('CONTINUE')}
            </Button>
          </Stack>
        </div>
      </div>
    )
  }

  const failureBox = () => {
    return (
      <div className='Success-reservation'>
        <div className='success-box'>
          <HomeRoundedIcon className="error-icon" />
          <h1 className='success-title'>{t('Por favor, quédese en casa')}</h1>
          <div className="detail-list-wrapper">
            <div className='detail-list'>
              <p>Gracias por completar el chequeo diario de salud.</p>
              <p>Debido a que está experimentando síntomas o estuvo en contacto con una persona diagnosticada con COVID-19, le solicitamos <strong>no asistir a la oficina</strong> y realizar sus labores desde casa.</p>
              <p>Si necesita ir a la oficina, por favor comuníquese con su jefatura.</p>
            </div>
          </div>
          <Stack spacing={2} className='success-footer'>
            <Button
              variant="outlined"
              onClick={() => navigate("/")} >
              {t('CONTINUE')}
            </Button>
          </Stack>
        </div>
      </div>
    )
  }

  const getStep = () => {
    if (activeStep === 'form') return getForm()
    if (activeStep === 'healthy') return successBox()
    if (activeStep === 'sick') return failureBox()
    return ''
  }

  return (
    <div className='New-reservation'>
      <Layout2
        title={t('Daily health check')}
        goBack={() => navigate("/")}>
        <div className="new-reservation">
          {getStep()}
        </div>
      </Layout2>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    companyName: state.profile.company.name
  }
}

const mapDispatchToProps = {
  dispatch,
  setLoading,
  defaultSuccess,
  defaultCatch
}

export default connect(mapStateToProps, mapDispatchToProps)(HealtForm)

