import React, { useCallback } from 'react'
import {
  Stack,
  Button,
  Dialog,
  Collapse,
  DialogContent,
} from '@mui/material'

import {
  InfoOutlined as InfoOutlinedIcon,
  ExpandMore as ExpandMoreIcon,
  WarningAmber as WarningAmberIcon
} from '@mui/icons-material'

import { useTranslation } from 'react-i18next'

import useCheck from '../../utilities/customHooks/useCheck'

import './ModalNotification.sass'
import { INFO, WARNING } from '../../../variables'

function ModalNotification(props) {
  const { t } = useTranslation()

  const {
    onCancel,
    onAccept,
    open,
    title = '',
    paragraph = '',
    message = '',
    type = INFO,
    cancelButton = true,
    cancelLabel = t('Reject'),
    acceptLabel = t('Accept')
  } = props

  const [openDetail, setOpenDetail] = useCheck(false)

  const getMainIcon = useCallback(() => {
    if (type === WARNING) return <WarningAmberIcon className="main-ico" sx={{ fontSize: 68 }} />
    return <InfoOutlinedIcon className="main-ico" sx={{ fontSize: 68 }} />
  }, [type])

  return (
    <Dialog
      className={`ModalNotification ${type}`}
      maxWidth="xs"
      open={open}>
      <DialogContent>
        <Stack
          spacing={2}
          alignItems="center"
        >
          {getMainIcon()}
          <h1>{title}</h1>
          <p>{paragraph}</p>
          {message &&
            <>
              <Button fullWidth variant="text" className='detail-button' onClick={setOpenDetail}>
                {t('If you want to know more click here')}
                <ExpandMoreIcon className={openDetail ? 'reverse' : ''} />
              </Button>
              <Collapse in={openDetail}>
                <p
                  className='detail'
                  dangerouslySetInnerHTML={{ __html: message }}
                />
              </Collapse>
            </>
          }
        </Stack>
        {!message && <br />}
        <Stack
          spacing={2}
          direction="row"
          className='detail-footer'>
          {!!cancelButton &&
            <Button
              size="large"
              fullWidth
              variant="outlined"
              onClick={onCancel} 
            >
              {cancelLabel}
            </Button>
          }
          <Button
            size="large"
            fullWidth
            variant="contained"
            onClick={onAccept} >
            {acceptLabel}
          </Button>
        </Stack>
      </DialogContent>
    </Dialog>
  )
}

export default ModalNotification