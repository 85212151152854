import React, { useState, useRef, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { defaultFunction } from '../../utilities/utilities'

import {
  Button, Stack
} from '@mui/material'

import {
  ArticleOutlined as ArticleOutlinedIcon,
  DeleteOutlineOutlined as DeleteOutlineOutlinedIcon,
  CachedOutlined as CachedOutlinedIcon,
} from '@mui/icons-material'

import './DragAndDrop.sass'
import UploadPillow from '../Svgs/UploadPillow'

function DragAndDrop(props) {
  const {
    onChange = defaultFunction,
    file = undefined,
    disabled = true,
    accept = ""
  } = props

  const { t } = useTranslation()
  const [dragActive, setDragActive] = useState(false)
  const inputRef = useRef(null)

  const onRemoveFile = useCallback(() => {
    onChange(null)
  }, [onChange])

  const handleDrag = useCallback(e => {
    if (disabled) return
    e.preventDefault()
    e.stopPropagation()
    if (
      e.type === 'dragenter' ||
      e.type === 'dragover'
    ) setDragActive(true)
    else if (e.type === 'dragleave')
      setDragActive(false)
  }, [disabled])

  const handleDrop = useCallback(e => {
    if (disabled) return
    e.preventDefault()
    e.stopPropagation()
    setDragActive(false)
    if (
      e.dataTransfer.files &&
      e.dataTransfer.files[0]
    ) onChange(e.dataTransfer.files[0])
  }, [disabled, onChange])

  const handleChange = useCallback(e => {
    if (disabled) return
    e.preventDefault()
    if (
      e.target.files &&
      e.target.files[0]
    ) onChange(e.target.files[0])
  }, [disabled, onChange])

  const getForm = useCallback(() => {
    return (
      <form
        onDragEnter={handleDrag}
        onSubmit={e => e.preventDefault()}>
        <input
          ref={inputRef}
          type="file"
          className="input-file-upload"
          onChange={handleChange}
          accept={accept}
        />
        <label
          htmlFor="input-file-upload"
          className={`label-file-upload ${dragActive ? "drag-active" : ""}`}>
          <Stack spacing={3} alignItems="center">
            <UploadPillow className='upload-icon' />
            <p>{t('Select or drag a file')}</p>
            <Button
              disabled={disabled}
              variant="contained"
              onClick={() => inputRef.current.click()}>
              {t('Find file')}
            </Button>
          </Stack>
        </label>
        {dragActive &&
          <div
            className="drag-file-element"
            onDragEnter={handleDrag}
            onDragLeave={handleDrag}
            onDragOver={handleDrag}
            onDrop={handleDrop}
          />
        }
      </form>
    )
  }, [accept, disabled, dragActive, handleChange, handleDrag, handleDrop, t])

  const getFileDetail = useCallback(() => {
    return (
      <div className='file-detail'>
        <Stack
          className='file-detail-name'
          direction="row"
          alignItems="center">
          <ArticleOutlinedIcon />
          <p>{file.name} </p>
          <Button onClick={onRemoveFile} color="error">
            <DeleteOutlineOutlinedIcon />
          </Button>
        </Stack>
        <Button onClick={onRemoveFile} startIcon={<CachedOutlinedIcon />}>
          Cambiar archivo
        </Button>
      </div>
    )
  }, [file, onRemoveFile])

  const getStep = useCallback(() => {
    if (file) return getFileDetail()
    return getForm()
  }, [file, getFileDetail, getForm])

  return (
    <div className={`DragAndDrop ${disabled ? 'disabled' : ''}`}>
      {getStep()}
    </div>
  )
}

export default DragAndDrop