const DeskDot = props => {
  const {
    className = '',
    style = {},
    width = 7,
    color = "#8C8CA1"
  } = props

  return (
    <svg
      className={className}
      style={style}
      width={width}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24">
      <circle cx="12" cy="12" r="11.5" fill={color} stroke="#fff" />
      <circle cx="11.8" cy="11.8" r="4.5" fill="#fff" />
    </svg>
  )
}

export default DeskDot