const Isotype = ({ className = '', style = {} }) => {
  return (
    <svg className={className} style={style} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 50 68">
      <g fill="#6C85D3" clipPath="url(#a)">
        <path d="m25 42 8.8-3.1 9.8-29.5a23.7 23.7 0 0 0-37.2 0l9.8 29.5L25 42Zm0-29.7a9 9 0 0 1 0 18 9 9 0 0 1 0-18ZM6 9.8c-3 4-4.8 9-4.8 14.5 0 3.2.7 6.5 2 10l12.4 4.3L6 9.8Zm9.8 29.4L3.4 34.8c1.6 4.3 4 8.6 6.5 12.6l7.7-2.8-1.8-5.4Zm31-5c1.2-3.4 2-6.7 2-10A24 24 0 0 0 44 9.9l-9.6 28.9 12.4-4.5ZM10.2 47.8c5.9 9 12.7 16.3 14.4 18l-6.9-20.7-7.5 2.7ZM32 44.5l1.6-5-7.9 2.8 6.3 2.2Zm-14 0 6.3-2.2-8-2.8 1.7 5Zm.2.5L25 65.4 31.8 45 25 42.5 18.2 45Zm7.2 20.8c1.7-1.7 8.5-9 14.4-18l-7.5-2.7-7 20.7Zm7-21.2 7.7 2.8a67 67 0 0 0 6.5-12.6l-12.4 4.4-1.8 5.4Z" />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h48.4v67.6H0z" transform="translate(.8)" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default Isotype