import React, { useState } from 'react';

function GeoPage() {
  const [location, setLocation] = useState(null);

  function getCurrentLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const latitude = position.coords.latitude;
          const longitude = position.coords.longitude;
          setLocation({ latitude, longitude });
        },
        (error) => {
          console.error("Error al obtener la geolocalización:", error);
        }
      );
    } else {
      console.error("Geolocalización no está disponible en este navegador.");
    }
  }

  return (
    <div>
      <button onClick={getCurrentLocation}>Obtener Geolocalización</button>
      {location && (
        <div>
          <h2>Geolocalización Actual:</h2>
          <p>Latitud: {location.latitude}</p>
          <p>Longitud: {location.longitude}</p>
          <hr />
          <p>{location.latitude}, {location.longitude}</p>
        </div>
      )}
    </div>
  );
}

export default GeoPage;