import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react'
import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import CloseIcon from '@mui/icons-material/Close'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Stack from '@mui/material/Stack'
import CircularProgress from '@mui/material/CircularProgress'

import Dialog from '@mui/material/Dialog'
import { DialogContent, useMediaQuery } from '@mui/material'
import { useTheme } from '@mui/material/styles'

import { connect } from "react-redux"
import { useNavigate } from "react-router-dom"
import { useTranslation } from 'react-i18next'
import Isotype from '../../common/components/Svgs/Isotype'
import Logo from '../../common/components/Svgs/Logo'
import { deviseAuth, ldapAuth, ssoAuth, oAuth, setLoading, setSnackbar, setDomain, defaultCatch, defaultSuccess } from '../../state/actions'
import { api, getBaseUrl, clearTokens, getRootUrl } from '../../api/api'
import { REGEX_EMAIL } from '../../variables'

import './Login.sass'

function Login(props) {
  const {
    loading,
    goHome,
    deviseAuth,
    ldapAuth,
    ssoAuth,
    oAuth,
    setLoading,
    setSnackbar,
    setDomain,
    defaultCatch,
    defaultSuccess
  } = props

  const { t } = useTranslation()
  const theme = useTheme()
  const isLarge = useMediaQuery(theme.breakpoints.up('md'))

  let navigate = useNavigate()
  const ref = useRef()
  const handleNavigate = useCallback(() => navigate('/load', { replace: true }), [navigate])

  const [authTypeData, setAuthTypeData] = useState('')
  const [baseUrl, setBaseUrl] = useState('')

  const [email, setEmail] = useState('')
  const [emailError, setEmailError] = useState('')
  const [password, setPassword] = useState('')
  const [passwordError, setPasswordError] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const [open, setOpen] = useState(false)
  const [showPasswordField, setShowPasswordField] = useState(false)
  const [ssoURL, setSsoURL] = useState('')
  const [oautUrl, setOAuthURL] = useState('')

  useEffect(() => {
    if (!ref.current) {
      ref.current = 1
      return
    }

    handleNavigate()
  }, [goHome, handleNavigate])

  const ssoAuthGenerator = useCallback((data, baseUrl) => {
    const url = ssoAuth(data, baseUrl)
    setSsoURL(url)
  }, [ssoAuth])

  const oAuthGenerator = useCallback(baseUrl => {
    const url = oAuth(baseUrl)
    setOAuthURL(url)
  }, [oAuth])

  const classicAuth = useCallback((data, baseUrl) => {
    setAuthTypeData(data)
    setBaseUrl(baseUrl)
    setShowPasswordField(true)
  }, [])

  const authType = useCallback(callback => {
    return api.post('/auth/type', { email })
      .then(({ data }) => {
        const { domains = [], sso, oauth } = data
        const domain = domains[0]
        setDomain(domain)
        const baseUrl = getBaseUrl(domain)
        const rootUrl = getRootUrl()
        if (callback) return callback(data, baseUrl)
        if (sso) return ssoAuthGenerator(data, baseUrl)
        if (oauth) return oAuthGenerator(rootUrl)
        return classicAuth(data, baseUrl)
      })
  }, [classicAuth, email, oAuthGenerator, setDomain, ssoAuthGenerator])

  const evalEmail = useCallback(() => {
    let error = ''

    if (
      email &&
      !REGEX_EMAIL.test(email)
    ) {
      error = t('invalid email')
    }

    setEmailError(error)
    return error
  }, [email, t])

  const handleTypeAuth = useCallback(() => {
    const emailError = evalEmail()
    if (emailError) return
    setLoading(true)
    authType()
      .finally(() => setLoading(false))
  }, [authType, evalEmail, setLoading])


  const handleEmail = useCallback(e => {
    const email = e.target.value
    setSsoURL('')
    setOAuthURL('')
    setPassword('')
    setEmailError('')
    setPasswordError('')
    setShowPasswordField('')
    clearTokens()
    setEmail(email)
  }, [])

  const handlePassword = e => {
    setPasswordError('')
    setPassword(e.target.value)
  }

  const isDisabled = useCallback(
    () => loading || !(email && password),
    [email, loading, password])

  const isDisabledNext = useCallback(
    () => loading || !!emailError || !email,
    [email, emailError, loading]
  )


  const isDisabledRecover = () => !email
  const handleClose = useCallback(() => {
    setOpen(false)
  }, [])

  const loginCatch = useCallback(e => {
    if (e?.response?.status === 401) {
      if (Array.isArray(e?.response?.data?.errors))
        setPasswordError(t(e.response.data.errors[0]))
      else setPasswordError(t('password is incorrect'))
    } else if (e?.response?.message) {
      setPasswordError(e?.response?.message)
    } else if (e?.message) {
      setPasswordError(t(e?.message))
    } else setPasswordError(t('unknown error'))
  }, [t])

  const handleClassicAuth = useCallback(() => {
    const { ldap } = authTypeData

    setLoading(true)

    if (ldap) {
      return ldapAuth({ password }, authTypeData)
        .catch(loginCatch)
        .finally(() => setLoading(false))
    }


    return deviseAuth({ email, password }, baseUrl)
      .catch(loginCatch)
      .finally(() => setLoading(false))

  }, [authTypeData, baseUrl, deviseAuth, email, ldapAuth, loginCatch, password, setLoading])



  const emailProps = useMemo(() => {
    return ({
      className: 'cp-text-field',
      fullWidth: true,
      size: 'small',
      autoComplete: 'on',
      variant: 'standard',
      placeholder: isLarge ? t('enter your email') : undefined,
      label: !isLarge ? t('email') : undefined,
      type: 'email'
    })
  }, [isLarge, t])

  const passwordProps = useMemo(() => {
    return ({
      className: 'cp-text-field',
      fullWidth: true,
      size: 'small',
      autoFocus: true,
      variant: 'standard',
      placeholder: isLarge ? t('enter your password') : undefined,
      label: !isLarge ? t('password') : undefined
    })
  }, [isLarge, t])

  const handleKeyPress = useCallback(event => {
    if (event.key !== 'Enter') return
    if (isDisabled()) return
    handleClassicAuth()
  }, [handleClassicAuth, isDisabled])

  const handleEmailKeyPress = useCallback(event => {
    if (
      !email ||
      event.key !== 'Enter' ||
      loading ||
      showPasswordField
    ) return

    handleTypeAuth()
  }, [email, handleTypeAuth, loading, showPasswordField])

  const handleResetKeyPress = useCallback(event => {
    if (
      !email ||
      event.key !== 'Enter' ||
      loading
    ) return

    handleTypeAuth()
  }, [email, handleTypeAuth, loading])


  const sendResetPasswordMail = useCallback(() => {
    setLoading(true)

    authType(() =>
      api.post('/password/reset', { email })
        .then(defaultSuccess)
        .then(() => {
          setSnackbar({
            open: true,
            message: t("we have sent you an email to x to change your password", { x: email }),
            severity: 'success',
            autoHideDuration: 2000
          })
          handleClose()
        })
        .catch(error => defaultCatch(error, sendResetPasswordMail)
        )
    )
  }, [authType, defaultCatch, defaultSuccess, email, handleClose, setLoading, setSnackbar, t])

  const forgetPasswordDialog = () => {
    return (
      <Dialog
        className="dialog-reset-password"
        fullScreen={!isLarge}
        open={open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth="sm"
        aria-labelledby="responsive-dialog-title">
        <DialogContent>
          <div className="dialog-content-inner">
            <IconButton
              className="close-button"
              aria-label="close"
              onClick={handleClose}
            >
              <CloseIcon />
            </IconButton>
            <h1>{t('did you forget your password')}</h1>
            <TextField
              size="small"
              id="email-reset"
              variant="standard"
              fullWidth={true}
              label={t('email')}
              placeholder={t('enter your email')}
              onKeyPress={handleResetKeyPress}
              value={email}
              autoComplete="off"
              type="email"
              onChange={handleEmail}
            />
            <Button
              className="cp-button"
              fullWidth
              size="large"
              variant="contained"
              onClick={sendResetPasswordMail}
              disabled={isDisabledRecover()}>
              {t('RECOVER PASSWORD')}
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    )
  }

  const openAuthTab = useCallback(() => {
    if (ssoURL) window.global_tab = window.open(ssoURL)
    else window.global_tab = window.open(oautUrl)
  }, [ssoURL, oautUrl])

  const isDisabledSSOAndOAuth = useCallback(
    () => loading || !email,
    [email, loading]
  )

  const handleSSOAndOAuthEmailKeyPress = useCallback(event => {
    if (event.key !== 'Enter') return
    if (isDisabledSSOAndOAuth()) return
    openAuthTab()
  }, [openAuthTab, isDisabledSSOAndOAuth])

  const getButtons = useCallback(() => {
    if (showPasswordField) return (
      <Button
        className="cp-button"
        fullWidth
        size="large"
        variant="contained"
        disabled={isDisabled()}
        onClick={handleClassicAuth}>
        {loading ? (
          <CircularProgress
            size={24}
          />
        ) : t("login")
        }
      </Button>
    )

    return (
      <Button
        className="cp-button next"
        fullWidth
        size="large"
        variant="contained"
        disabled={isDisabledNext()}
        onClick={handleTypeAuth}>
        {loading ? (
          <CircularProgress
            size={24}
          />
        ) : t("next")
        }
      </Button>
    )
  }, [handleClassicAuth, handleTypeAuth, isDisabled, isDisabledNext, loading, showPasswordField, t])


  const ssoAndOAuthLoginForm = useCallback(() => {
    return (
      <div className="login-inner sso">
        <Isotype className="logo" />
        <h1 className='welcome-message'>
          {isLarge ?
            t('we welcome you') :
            t('we welcome you to the corporate experience')
          }
        </h1>
        <Stack spacing={2}>
          {isLarge &&
            <label className="cp-label" htmlFor='email'>
              {t('email')}
            </label>
          }
          <TextField
            id="email"
            {...emailProps}
            value={email}
            error={!!emailError}
            helperText={emailError}
            onKeyPress={handleSSOAndOAuthEmailKeyPress}
            onChange={handleEmail}
          />
          <Button
            className="cp-button next"
            fullWidth
            size="large"
            variant="contained"
            onClick={openAuthTab}>
            {
              ssoURL ?
                t('login with SSO') :
                t('login with OAuth')
            }
          </Button>
          <a
            className="privacy"
            href="https://www.parso.co/politica-de-privacidad-corporate-experience"
            target="_blank"
            rel="noreferrer">
            {t('privacy policy')}
          </a>
        </Stack>
      </div>
    )
  }, [isLarge, t, emailProps, email, emailError, handleSSOAndOAuthEmailKeyPress, handleEmail, openAuthTab, ssoURL])

  const classicLoginForm = useCallback(() => {
    return (
      <div className="login-inner">
        <Isotype className="logo" />
        <h1 className='welcome-message'>
          {isLarge ?
            t('we welcome you') :
            t('we welcome you to the corporate experience')
          }
        </h1>
        <Stack spacing={2}>
          {isLarge &&
            <label className="cp-label" htmlFor='email'>
              {t('email')}
            </label>
          }
          <TextField
            id="email"
            {...emailProps}
            value={email}
            error={!!emailError}
            helperText={emailError}
            onKeyPress={handleEmailKeyPress}
            onChange={handleEmail}
            pattern=".+@globex\.com"
            required
          />
          {showPasswordField && <>
            {isLarge && <span className="cp-span">
              <Button
                variant="text"
                onClick={() => setOpen(true)}>
                {t('did you forget your password')}
              </Button>
              <label
                className="cp-label"
                htmlFor='password'>
                {t('password')}
              </label>
            </span>}
            <TextField
              {...passwordProps}
              type={showPassword ? 'text' : 'password'}
              value={password}
              error={!!passwordError}
              helperText={passwordError}
              onChange={handlePassword}
              onKeyPress={handleKeyPress}
              InputProps={{
                endAdornment:
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
              }}
            />
            {!isLarge &&
              <Button
                variant="text"
                onClick={() => setOpen(true)}>
                {t('did you forget your password')}
              </Button>
            }
          </>}

          {getButtons()}

          <a
            className="privacy"
            href="https://www.parso.co/politica-de-privacidad-corporate-experience"
            target="_blank"
            rel="noreferrer">
            {t('privacy policy')}
          </a>
        </Stack>
      </div>
    )
  }, [isLarge, t, emailError, emailProps, email, handleEmailKeyPress, handleEmail, showPasswordField, passwordProps, showPassword, password, passwordError, handleKeyPress, getButtons])

  const getForm = useCallback(() => {
    if (ssoURL || oautUrl) return ssoAndOAuthLoginForm()
    return classicLoginForm()
  }, [classicLoginForm, ssoAndOAuthLoginForm, ssoURL, oautUrl])

  return (
    <div className="Login">
      <Logo className="full-logo" />
      {getForm()}
      {forgetPasswordDialog()}
    </div>
  )
}

const mapDispatchToProps = {
  deviseAuth,
  ldapAuth,
  ssoAuth,
  oAuth,
  setLoading,
  setSnackbar,
  setDomain,
  defaultCatch,
  defaultSuccess
}

const mapStateToProps = state => {
  return {
    goHome: state.profile.goHome,
    loading: state.backdrop.loading
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login)