import { MenuItem } from "@mui/material"
import { t } from "i18next"


const MenuItemDefault = () => {
  return (
    <MenuItem value="-1" disabled>
      <em>{t('select')}</em>
    </MenuItem>
  )
}

export default MenuItemDefault
