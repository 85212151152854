import React from 'react'

import './LayoutError.sass'

function LayoutError(props) {
  const { children } = props

  return (
    <div className='LayoutError'>
      <div className='info-error'>
        {children}
      </div>
      <div className='footer-error'></div>
    </div>
  );
}


export default LayoutError
