import { connect } from 'react-redux'
import React, { useState } from 'react'
import SwipeableCalendar from '../SwipeableCalendar/SwipeableCalendar'
import InputAdornment from '@mui/material/InputAdornment'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

import {
  Hidden,
  MenuItem,
  Select,
  TextField,
  Button,
  Stack
} from '@mui/material';

import {
  formatDateToMed
} from '../../utilities/FormatDate'

import {
  Add as AddIcon,
  Event as EventIcon
} from '@mui/icons-material/'

import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import './Filters.sass'

const today = new Date()
const defaultFunction = () => { }


function Filters(props) {

  const {
    type = -1,
    status = -1,
    date = [null, null],
    setType = defaultFunction,
    setStatus = defaultFunction,
    setDate = defaultFunction,
    onFilter = defaultFunction,
    enabled_desk,
    enabled_room,
    enabled_parking,
    enabled_workplace,
    enabled_cafeteria,
    workplaceCustomLabel
  } = props

  const navigate = useNavigate()
  const { t } = useTranslation()

  const theme = useTheme()
  const isLarge = useMediaQuery(theme.breakpoints.up('md'))
  const [openCalendar, setOpenCalendar] = useState(false)
  const [dateCalendar, setDateCalendar] = useState(date)

  const getFilterDate = () => {
    const [start, end] = date
    if (!start || !end) return t('all')
    return `${formatDateToMed(start)} - ${formatDateToMed(end)}`
  }

  const onContinue = () => {
    setDate(dateCalendar)
    setOpenCalendar(false)
    onFilter(dateCalendar)
  }

  const onCloseCalendar = () => {
    setDateCalendar(date)
    setOpenCalendar(false)
  }

  const isDisabledCalendar = () => {
    const [start, end] = dateCalendar
    return (!(start && end))
  }

  return (
    <div className="filters">
      <Stack className="cp-stack" direction="row" spacing={2}>
        {isLarge && <label htmlFor="type">{t('type')}:</label>}
        <Select
          className="cp-select"
          name="type"
          value={type}
          displayEmpty
          onChange={e => setType(e.target.value)}
        >
          <MenuItem value={-1}>
            <em>{t('all')}</em>
          </MenuItem>
          {enabled_parking && <MenuItem value="ParkingReservation">{t('parking')}</MenuItem>}
          {enabled_workplace && enabled_desk && <MenuItem value="Workplace::DeskReservation">{workplaceCustomLabel ? workplaceCustomLabel : t('workplace')}</MenuItem>}
          {enabled_workplace && enabled_room && <MenuItem value="Workplace::RoomReservation">{t('rooms')}</MenuItem>}
          {enabled_parking && <MenuItem value="PARKING_ASSIGNATION">{t('parking assigantions')}</MenuItem>}
          {enabled_workplace && enabled_desk && <MenuItem value="DESK_ASSIGNATION">{t('desk assignations')}</MenuItem>}
          {enabled_cafeteria && <MenuItem value="CAFETERIA_RESERVATION">{t('Cafeteria Order')}</MenuItem>}
        </Select>
        {isLarge && <label htmlFor="type">{t('State')}:</label>}
        <Select
          className="cp-select"
          name="type"
          value={status}
          displayEmpty
          onChange={e => setStatus(e.target.value)}
        >
          <MenuItem value={-1}>
            <em>{t('all')}</em>
          </MenuItem>
          <MenuItem value="PENDING">{t('pending')}</MenuItem>
          <MenuItem value="APPROVED">{t('approved')}</MenuItem>
          <MenuItem value="REJECTED">{t('rejected')}</MenuItem>
          <MenuItem value="ABSENCE">{t('absence')}</MenuItem>
          <MenuItem value="DISABLED">{t('disabled')}</MenuItem>
        </Select>
        {isLarge && <label>{t('date')}:</label>}
        <TextField
          value={getFilterDate()}
          onClick={() => setOpenCalendar(true)}
          autoComplete="off"
          size="small"
          InputProps={{
            endAdornment:
              <InputAdornment position="end">
                <EventIcon />
              </InputAdornment>
          }}
        />
        <SwipeableCalendar
          type="range"
          open={openCalendar}
          date={dateCalendar}
          minDate={today}
          setDate={setDateCalendar}
          setOpen={setOpenCalendar}
          onClose={onCloseCalendar}
          onContinue={onContinue}
          isDisabled={isDisabledCalendar()}
          clearable
        />
        <Hidden mdDown>
          <Button
            className="ml-auto cp-button new-reservation-filter-button"
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => navigate("/new-reservation")}
          >
            {t('new reservation')}
          </Button>
          <Button
            className="ml-auto cp-button new-reservation-filter-min-button"
            variant="contained"
            onClick={() => navigate("/new-reservation")}
          >
            <AddIcon />
          </Button>
        </Hidden>
      </Stack>
    </div>
  );
}

const mapStateToProps = state => {
  return {
    enabled_parking: state.profile.enabled_parking,
    enabled_workplace: state.profile.enabled_workplace,
    enabled_room: state.profile.enabled_room_reservations,
    enabled_desk: state.profile.company.enabled_desk_reservations,
    enabled_cafeteria: state.profile.company.enabled_cafeteria,
    workplaceCustomLabel: state.profile.company.workplace_custom_label
  }
}

export default connect(mapStateToProps)(Filters)

