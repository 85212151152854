import React from 'react';
import { Box } from '@mui/material';

const MessageBox = ({ type, message }) => {
  const getStyles = (type) => {
    switch (type) {
      case 'error':
        return {
          borderLeft: '1.5px solid #CF706F',
          backgroundColor: 'rgba(207, 112, 111, 0.10)',
          color: '#D0021B',
        };
      case 'info':
        return {
          borderLeft: '1.5px solid #5C9CCE',
          backgroundColor: 'rgba(92, 156, 206, 0.10)',
          color: '#3071A9',
        };
      case 'success':
        return {
          borderLeft: '1.5px solid #6FBA82',
          backgroundColor: 'rgba(111, 186, 130, 0.10)',
          color: '#28A745',
        };
      default:
        return {};
    }
  };

  const styles = {
    borderRadius: '0px 8px 8px 0px',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: 'normal',
    padding: '8px 16px',
    display: 'flex',  
    alignItems:'flex-start',
    gap: '10px',
    margin: '10px 4px',
    ...getStyles(type),
  };

  return (
    <Box sx={styles}>
      {message}
    </Box>
  );
};

export default MessageBox;