import { Types } from '../actionTypes'

const initialState = {
  lang: 'es'
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case Types.SET_LANGUAGE:
      const lang = action.payload
      return { ...state, lang }
    default:
      return state
  }
}

export default reducer;