import { useTranslation } from 'react-i18next'
import Layout from '../../common/components/Layout/Layout'

import './Help.sass';

function Help() {
  const { t } = useTranslation();
  return (
    <Layout title={t('help')}>
      <div className="Help">
        {t('help')}
      </div>
    </Layout>
  );
}

export default Help;