export const Types = {
  SET_TRUE_HAS_ACCEPTED_PRIVACY_POLICY: 'SET_TRUE_HAS_ACCEPTED_PRIVACY_POLICY',
  SET_RESERVATIONS_AND_ASSIGNATIONS: 'SET_RESERVATIONS_AND_ASSIGNATIONS',
  SET_PARKING_TOTAL_RESERVATIONS: 'SET_PARKING_TOTAL_RESERVATIONS',
  SET_DESK_TOTAL_RESERVATIONS: 'SET_DESK_TOTAL_RESERVATIONS',
  SET_ROOM_TOTAL_RESERVATIONS: 'SET_ROOM_TOTAL_RESERVATIONS',
  SET_TRUE_GRAPH_USER_ID: 'SET_TRUE_GRAPH_USER_ID',
  SET_BACKDROP_LOADING: 'SET_BACKDROP_LOADING',
  SET_SNACKBAR_TOGGLE: 'SET_SNACKBAR_TOGGLE',
  SET_BACKDROP_TOGGLE: 'SET_BACKDROP_TOGGLE',
  SET_SNACKBAR_DATA: 'SET_SNACKBAR_DATA',
  SET_IS_ONLINE: 'SET_IS_ONLINE',
  SET_LANGUAGE: 'SET_LANGUAGE',
  CLEAN_AUTH: 'CLEAN_AUTH',
  SET_DOMAIN: 'SET_DOMAIN',
  SET_SITES: 'SET_SITES',
  SET_CAFETERIA_TOTAL_ORDERS: 'SET_CAFETERIA_TOTAL_ORDERS',
  SET_AUTH: 'SET_AUTH',
  SET_LOGO: 'SET_LOGO',
  SET_QRCODE: 'SET_QRCODE',
  SET_COMPANIES: 'SET_COMPANIES',
  SET_PARKING_RESERVATION_DATA: 'SET_PARKING_RESERVATION_DATA',
  CLEAR_PARKING_RESERVATION_DATA: 'CLEAR_PARKING_RESERVATION_DATA'
}