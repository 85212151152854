import { useEffect } from 'react'
import { useIntercom } from 'react-use-intercom'

function SupportIntercom() {
  const { showNewMessages, hide } = useIntercom()

  useEffect(() => {
    showNewMessages()
    return () => hide()
  })

  return ''
}

export default SupportIntercom