import { Types } from '../actionTypes'

const initialState = {
  total: 0,
  pendingReservation: null
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case Types.SET_PARKING_TOTAL_RESERVATIONS:
      const total = action.payload
      return { ...state, total }
    case Types.CLEAN_AUTH:
      return initialState
    case Types.SET_PARKING_RESERVATION_DATA:
      return {
        ...state,
        pendingReservation: action.payload
      }
    case Types.CLEAR_PARKING_RESERVATION_DATA:
      return {
        ...state,
        pendingReservation: null
      }
    default:
      return state
  }
}

export default reducer;