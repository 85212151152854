import * as React from 'react'
import Box from '@mui/material/Box'
import List from '@mui/material/List'
import Drawer from '@mui/material/Drawer'
import ListItem from '@mui/material/ListItem'
import MenuOpenRoundedIcon from '@mui/icons-material/MenuOpenRounded'
import KeyboardArrowRightRoundedIcon from '@mui/icons-material/KeyboardArrowRightRounded'
import IconButton from '@mui/material/IconButton'
import ListItemText from '@mui/material/ListItemText'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { connect } from "react-redux"

import "./Menu.sass"

function Menu({ open, setOpen, enabledVisits }) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const menuRoutes = [
    { name: t('my reservations'), url: '/' },
  ]

  if (enabledVisits) menuRoutes.push({ name: t('pre registration'), url: '/visitors' })

  const list = () => (
    <Box
      role="presentation"
      onClick={() => setOpen(false)}
      onKeyDown={() => setOpen(false)}
    >
      <IconButton
        className="close-menu-icon"
        aria-label="menu"
        onClick={() => setOpen(!open)}
      >
        <MenuOpenRoundedIcon />
      </IconButton>
      <List>
        {menuRoutes.map((item, key) => (
          <ListItem key={key} button onClick={() => navigate(item.url)}>
            <KeyboardArrowRightRoundedIcon />
            <ListItemText primary={item.name} />
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <Drawer
      className="Menu"
      anchor='left'
      open={open}
      onClose={() => setOpen(false)} >
      {list()}
    </Drawer>
  );
}

const mapStateToProps = state => {
  return {
    enabledVisits: state.profile.enabled_visits,
  }
}


export default connect(mapStateToProps)(Menu)
