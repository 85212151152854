import * as React from 'react'
import { connect } from 'react-redux'
import { Types } from '../../../state/actionTypes'
import { Snackbar, Alert } from '@mui/material'

function GlobalSnackbar(props) {
  const {
    open,
    severity,
    message = '',
    vertical,
    horizontal,
    autoHideDuration,
    dispatch
  } = props

  const handleClose = () => dispatch({ type: Types.SET_SNACKBAR_TOGGLE })
  return (
    <Snackbar
      open={open}
      anchorOrigin={{ vertical, horizontal }}
      autoHideDuration={autoHideDuration}
      onClose={handleClose}>
      <Alert
        onClose={handleClose}
        severity={severity}
        sx={{ width: '100%' }}
        md={{ width: '100%' }}
      >
        {message}
      </Alert>
    </Snackbar>
  )
}

const mapStateToProps = state => {
  return {
    open: state.snackbar.open,
    message: state.snackbar.message,
    severity: state.snackbar.severity,
    vertical: state.snackbar.vertical,
    horizontal: state.snackbar.horizontal,
    autoHideDuration: state.snackbar.autoHideDuration,
  }
}

export default connect(mapStateToProps)(GlobalSnackbar)
