import React, { useCallback } from 'react'
import { connect } from "react-redux"

import {
  MapContainer,
  Marker,
  Tooltip
} from 'react-leaflet'

import { Stack } from '@mui/material'

import * as L from 'leaflet'
import './FloorMap.sass'
import { setLoading } from '../../../state/actions'
import {
  CENTER,
  DEFAULT_ZOOM,
  MIN_ZOOM
} from '../../../variables'
import { getPosition } from '../../utilities/utilities'
import { useTranslation } from 'react-i18next'
import { formatNoTZTimeFromISOtoAMPM } from '../../utilities/FormatDate'

const defaultFunction = () => { }


function FloorMap(props) {
  const {
    className,
    markers = [],
    floorImage,
    zoom = DEFAULT_ZOOM,
    center,
    minZoom = MIN_ZOOM,
    onClickMarker = defaultFunction,
    setLoading
  } = props

  const { t } = useTranslation()

  const whenReady = ({ target: map }) => {
    const { url, size } = floorImage
    const _size = [...size]
    const bounds = [[0, 0], _size.reverse()]
    L.imageOverlay(url, bounds).addTo(map)
    if (center) map.setView(center, zoom)
    else {
      map.fitBounds(bounds)
      setTimeout(() => map.setZoom(zoom), 500)
    }
    setLoading(false)
  }

  const getIcon = m => {
    let iconUrl = 'green_dot.svg'
    let shadowUrl = null

    if (m.selected) iconUrl = 'selected_dot.svg'
    else if (m.selection) {
      iconUrl = 'green_blue_dot.svg'
      shadowUrl = 'green_blue_dot_background.svg'
    }
    else if (m.occupied) iconUrl = 'red_dot.svg'

    return L.icon({
      iconUrl,
      iconSize: [32, 32],
      iconAnchor: [16, 16],
      popupAnchor: null,
      shadowUrl: shadowUrl,
      shadowSize: [64, 64],
      shadowAnchor: [32, 32],
    })
  }

  const markerHandlersEvent = useCallback(marker => {
    return {
      click: e => onClickMarker(e, marker),
    }
  }, [onClickMarker])

  const getMarkers = useCallback(() => {
    const imageHeight = floorImage.size[1];
    return markers.map((m, idx) => {

      const {
        usage = [],
        occupied = false
      } = m

      return (
        <Marker
          key={`marker-${idx}`}
          icon={getIcon(m)}
          eventHandlers={markerHandlersEvent(m)}
          position={getPosition(m, imageHeight)}
        >
          {occupied && (
            <Tooltip direction="bottom" offset={[0, 10]} opacity={1} >
              {usage.length !== 0 && (usage[0].user.first_name || usage[0].user.last_name) ?
                <Stack direction='column' alignItems='center'>
                  <p>{t('Space booked by')}:</p>
                  <p>{usage[0].user.first_name} {usage[0].user.last_name}</p>
                  <p>{formatNoTZTimeFromISOtoAMPM(usage[0].entry_time)} - {formatNoTZTimeFromISOtoAMPM(usage[0].exit_time)}</p>
                </Stack>
                :
                t('This space is not available for booking')
              }
            </Tooltip>
          )}
        </Marker>
      )
    });
  }, [floorImage, markerHandlersEvent, markers, t]);

  return (
    <div className={`FloorMap ${className}`}>
      {floorImage &&
        <MapContainer
          zoom={DEFAULT_ZOOM}
          minZoom={minZoom}
          center={CENTER}
          crs={L.CRS.Simple}
          attributionControl={false}
          whenReady={whenReady}>
          {getMarkers()}
        </MapContainer>
      }
    </div>
  )
}

const mapDispatchToProps = {
  setLoading
}

export default connect(null, mapDispatchToProps)(FloorMap)
