import { useState } from 'react';

function useCheck(initialState = false) {
    const [checked, setChecked] = useState(initialState);

    function handleChange() {
        setChecked(!checked);
    }

    return [checked, handleChange];
}

export default useCheck