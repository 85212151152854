const Dot = ({ className = '', style = {}, width = 7 }) => {
  return (
    <svg
      className={className}
      style={style}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 8 8"
      width={width}
    >
      <circle cx="4.159" cy="4.478" r="3.519" fill="#74BAB9" />
    </svg>
  )
}

export default Dot