class RequestQueue {
  constructor() {
    this.queue = [];
  }

  addRequest(request) {
    this.queue.push(request);
    if (this.queue.length === 1) {
      this.next();
    }
  }

  next() {
    if (this.queue.length === 0) return;
    const { request, resolve, reject } = this.queue[0];
    request()
      .then(resolve)
      .catch(reject)
      .finally(() => {
        this.queue.shift();
        this.next();
      });
  }
}

export const requestQueue = new RequestQueue();