import { createTheme } from '@mui/material/styles'

const defaultPrimaryMain = "#6C85D3"
const defaultSecundaryMain = "#50629C"
const defaultTertiaryMain = "#EEF2FF"

const themeGenerator = params => {
  const {
    primaryMain = defaultPrimaryMain,
    secondaryMain = defaultSecundaryMain,
    tertiaryMain = defaultTertiaryMain
  } = params

  document.documentElement.style.setProperty('--primary', primaryMain)
  document.documentElement.style.setProperty('--secundary', secondaryMain)
  document.documentElement.style.setProperty('--tertiary', tertiaryMain)

  return createTheme({
    typography: {
      button: { textTransform: 'none' }
    },
    palette: {
      primary: { main: primaryMain },
      secondary: { main: secondaryMain },
      tertiary: { main: tertiaryMain },
      error: { main: "#CF706F" },
      success: { main: "#128724" },
      info: { main: "#1D8099" }
    }
  })
}

export {
  themeGenerator
}