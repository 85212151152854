import { useEffect } from 'react'
import { connect } from "react-redux"
import { useNavigate } from 'react-router-dom'
import { logout } from '../../state/actions'


function Logout(props) {
  const {
    logout
  } = props

  const navigate = useNavigate()
  useEffect(() => {
    logout(navigate)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return ('')
}

export default connect(null, { logout })(Logout)