import React, { useState } from 'react';
import { Hidden, IconButton } from '@mui/material';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import Header from '../Header/Header'
import Menu from '../Menu/Menu'

import './Layout.sass'

function Layout(props) {
  const { children, title = '' } = props;
  const [open, setOpen] = useState(false);

  return (
    <>
      <Header />
      <div className='layout'>
        <Hidden mdDown>
          <div className="menu-wrapper">
            <IconButton
              size="large"
              edge="start"
              color="primary"
              aria-label="menu"
              onClick={() => setOpen(!open)}
            >
              <MenuRoundedIcon />
            </IconButton>
          </div>
        </Hidden>
        <div className="content-wrapper">
          <Hidden mdUp>
            <IconButton
              className="cp-icon-menu"
              size="large"
              edge="end"
              color="primary"
              aria-label="menu"
              onClick={() => setOpen(!open)}
            >
              <MenuRoundedIcon />
            </IconButton>
          </Hidden>
          <h1 className='d-ib'>{title}</h1>
          {children}
        </div>
      </div>
      <Menu open={open} setOpen={setOpen} />
    </>
  );
}


export default Layout
