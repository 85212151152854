import React from 'react';
import { Stepper, Step, StepLabel, StepConnector } from '@mui/material';
import './CustomizedStepper.sass';

const CustomStepIcon = (props) => {
  const { active, completed } = props;

  let className = 'custom-step-icon';
  if (active) className += ' active';
  if (completed) className += ' completed';

  return (
    <div className={className}>
      <div className="step-icon-text">{props.icon}</div>
    </div>
  );
}

const steps = [0, 1, 2]

const CustomizedStepper = ({activeStep}) => {
  return (
    <Stepper
      alternativeLabel
      activeStep={activeStep ? activeStep : 0}
      className="stepper-container"
      connector={<StepConnector classes={{ root: 'MuiStepConnector-root' }} />}
    >
      {steps.map((label, index) => (
        <Step key={label}>
          <StepLabel StepIconComponent={CustomStepIcon} />
        </Step>
      ))}
    </Stepper>
  );
}

export default CustomizedStepper;