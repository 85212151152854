const Building = ({ className = '', style = {}, width = 7 }	) => {
	return (
		<svg
			className={className}
			style={style}
			xmlns="http://www.w3.org/2000/svg"
			fill="none"
			viewBox="0 0 9 16"
			width={width}
		>
			<path fill="#9B9B9B" d="M7.33317 5.33333V0H0.666504V2.66667V12H3.33317V9.33333H4.6665V12H7.33317V5.33333ZM3.33317 8H1.99984V6.66667H3.33317V8ZM3.33317 5.33333H1.99984V4H3.33317V5.33333ZM3.33317 2.66667H1.99984V1.33333H3.33317V2.66667ZM5.99984 8H4.6665V6.66667H5.99984V8ZM5.99984 5.33333H4.6665V4H5.99984V5.33333ZM5.99984 2.66667H4.6665V1.33333H5.99984V2.66667Z" />
		</svg>
	)
}

export default Building;