
const LockPerson = ({ className = '', style = {}, width = 14, fill }) => {
    return (
      <svg
        className={className}
        style={style}
        xmlns="http://www.w3.org/2000/svg"
        fill={fill}
        viewBox="0 0 25 24"
        width={width}
      >
        <path fill={fill ? fill : 'var(--secundary)'} d="M18.25 18C18.6667 18 19.0208 17.8542 19.3125 17.5625C19.6042 17.2708 19.75 16.9167 19.75 16.5C19.75 16.0833 19.6042 15.7292 19.3125 15.4375C19.0208 15.1458 18.6667 15 18.25 15C17.8333 15 17.4792 15.1458 17.1875 15.4375C16.8958 15.7292 16.75 16.0833 16.75 16.5C16.75 16.9167 16.8958 17.2708 17.1875 17.5625C17.4792 17.8542 17.8333 18 18.25 18ZM18.25 21C18.75 21 19.2167 20.8833 19.65 20.65C20.0833 20.4167 20.4417 20.0917 20.725 19.675C20.3417 19.4417 19.9417 19.2708 19.525 19.1625C19.1083 19.0542 18.6833 19 18.25 19C17.8167 19 17.3917 19.0542 16.975 19.1625C16.5583 19.2708 16.1583 19.4417 15.775 19.675C16.0583 20.0917 16.4167 20.4167 16.85 20.65C17.2833 20.8833 17.75 21 18.25 21ZM9.25 8H15.25V6C15.25 5.16667 14.9583 4.45833 14.375 3.875C13.7917 3.29167 13.0833 3 12.25 3C11.4167 3 10.7083 3.29167 10.125 3.875C9.54167 4.45833 9.25 5.16667 9.25 6V8ZM12.5 22H6.25C5.7 22 5.22917 21.8042 4.8375 21.4125C4.44583 21.0208 4.25 20.55 4.25 20V10C4.25 9.45 4.44583 8.97917 4.8375 8.5875C5.22917 8.19583 5.7 8 6.25 8H7.25V6C7.25 4.61667 7.7375 3.4375 8.7125 2.4625C9.6875 1.4875 10.8667 1 12.25 1C13.6333 1 14.8125 1.4875 15.7875 2.4625C16.7625 3.4375 17.25 4.61667 17.25 6V8H18.25C18.8 8 19.2708 8.19583 19.6625 8.5875C20.0542 8.97917 20.25 9.45 20.25 10V11.3C19.95 11.2 19.6375 11.125 19.3125 11.075C18.9875 11.025 18.6333 11 18.25 11V10H6.25V20H11.55C11.6833 20.4 11.8167 20.7458 11.95 21.0375C12.0833 21.3292 12.2667 21.65 12.5 22ZM18.25 23C16.8667 23 15.6875 22.5125 14.7125 21.5375C13.7375 20.5625 13.25 19.3833 13.25 18C13.25 16.6167 13.7375 15.4375 14.7125 14.4625C15.6875 13.4875 16.8667 13 18.25 13C19.6333 13 20.8125 13.4875 21.7875 14.4625C22.7625 15.4375 23.25 16.6167 23.25 18C23.25 19.3833 22.7625 20.5625 21.7875 21.5375C20.8125 22.5125 19.6333 23 18.25 23Z"  />
      </svg>
    )
  }
  
  export default LockPerson