import React, { useCallback, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Stack, Card, Button } from '@mui/material'
import QRCode from 'qrcode'

import {
  logout,
  setLoading,
  defaultSuccess,
  _dispatch as dispatch,
} from '../../state/actions'


import Layout from '../../common/components/Layout/Layout'

import {
  CheckCircle as CheckCircleIcon,
  Pending as PendingIcon,
  Error as ErrorIcon,
  Info as InfoIcon
} from '@mui/icons-material'


import './QR.sass'
import { api } from '../../api/api'
import { ERROR, OK } from '../../variables'
import { Types } from '../../state/actionTypes'

function QR(props) {

  const {
    qrCode,
    dispatch,
    setLoading,
    defaultSuccess
  } = props

  const navigate = useNavigate()
  const { t } = useTranslation()

  const [QRSrc, setQRSrc] = useState("")
  const [statusQR, setStatusQR] = useState()
  const [statusQRMessage, setStatusQRMessage] = useState(t('Remember not to share this code with anyone'))

  useEffect(() => {
    if (qrCode) {
      QRGenerator(qrCode)
      return
    }

    setLoading(true)
    api.get('/access_control/qr')
      .then(defaultSuccess)
      .then(({ data }) => {
        if (data.success) {
          QRGenerator(data.qr)
          dispatch({
            type: Types.SET_QRCODE,
            payload: data.qr
          })
        }
        else QRError(data)
      })
      .catch(QRError)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const QRGenerator = data => {
    QRCode.toDataURL(data)
      .then(url => {
        setQRSrc(url)
        setStatusQR(OK)
      })
      .catch(QRError)
  }

  const QRError = error => {
    setStatusQR(ERROR)
    setStatusQRMessage(error.message)
    console.error(error)
  }

  const getIcon = useCallback(() => {
    if (statusQR === ERROR) return <ErrorIcon sx={{ color: '#CD2A26', fontSize: 100 }} />
    else if (statusQR === OK) return <CheckCircleIcon sx={{ color: '#75D385', fontSize: 100 }} />
    return <PendingIcon sx={{ color: '#E5D166', fontSize: 100 }} />
  }, [statusQR])

  const getLabel = useCallback(() => {
    if (statusQR === ERROR) return <h4 className='error'>{t('Access code error')}</h4>
    else return <h4>{t('Access code')}</h4>
  }, [statusQR, t])

  return (
    <div className='QR'>
      <Layout>
        <Stack
          className='qr-wrapper'
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={3}
        >
          {getIcon()}
          {getLabel()}
          {statusQR === OK &&
            <Card>
              <img src={QRSrc} alt="QR code" />
            </Card>
          }
          <label>
            <Stack
              className="info-qr-text"
              direction='row'
              alignItems='center'
              columnGap={'8px'}

            >
              <InfoIcon sx={{ color: '#8C8CA1', fontSize: 15 }} />
              {statusQRMessage}
            </Stack>
          </label>
          <Button
            fullWidth
            size='large'
            variant="outlined"
            onClick={() => navigate("/")}>
            {t('close')}
          </Button>
        </Stack>
      </Layout>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    qrCode: state.profile.qrCode
  }
}

const mapDispatchToProps = {
  dispatch,
  logout,
  setLoading,
  defaultSuccess
}

export default connect(mapStateToProps, mapDispatchToProps)(QR)

