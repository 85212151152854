import { Types } from '../actionTypes'

const initialState = {
    total: 0
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case Types.SET_CAFETERIA_TOTAL_ORDERS:
            const total = action.payload
            return { ...state, total }
        default:
            return state
    }
}

export default reducer