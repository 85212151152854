import React from 'react';
import PedalBikeOutlinedIcon from '@mui/icons-material/PedalBikeOutlined';
import TwoWheelerOutlinedIcon from '@mui/icons-material/TwoWheelerOutlined';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import LockPerson from '../components/Svgs/LockPerson';
import PlaceIcon from '@mui/icons-material/Place';
import GroupsIcon from '@mui/icons-material/Groups';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import AirlineSeatReclineNormalIcon from '@mui/icons-material/AirlineSeatReclineNormal';
import ChairAltIcon from '@mui/icons-material/ChairAlt';

const CustomWorkplaceIcons = ({index, sx, color, width, className}) => {

  const workplaceIcons = [
    <PedalBikeOutlinedIcon sx={sx} width={width} className={className}/>,
    <TwoWheelerOutlinedIcon sx={sx} width={width} className={className} />,
    <DashboardOutlinedIcon sx={sx} width={width} className={className} />,
    <LockPerson width={width} fill={color} className={className} />,
    <PlaceIcon sx={sx} width={width} className={className} />,
    <GroupsIcon sx={sx} width={width} className={className} />,
    <RestaurantIcon sx={sx} width={width} className={className} />,
    <DirectionsCarIcon sx={sx} width={width} className={className} />,
    <AirlineSeatReclineNormalIcon sx={sx} width={width} className={className} />,
    <ChairAltIcon sx={sx} width={width} className={className} />
  ];

  return workplaceIcons[index];
}

export default CustomWorkplaceIcons;