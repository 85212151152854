import React, { useState, useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Divider, IconButton } from '@mui/material'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'
import Layout from '../../common/components/Layout/Layout'
import HtmlTooltip from '../../common/components/HtmlTooltip/HtmlTooltip'
import VehiclesModal from '../../common/components/VehiclesModal/VehiclesModal'
import { useNavigate } from 'react-router-dom'
import { connect } from 'react-redux'
import { api } from '../../api/api'
import { eval401Error, _dispatch as dispatch } from '../../state/actions'
import { Types } from '../../state/actionTypes'

import {
  Backdrop,
  CircularProgress
} from '@mui/material'

import {
  InfoOutlined as InfoOutlinedIcon,
  Edit as EditIcon
} from '@mui/icons-material'

import './Information.sass'
let iteration = 0

function Information(props) {
  const {
    eval401Error,
    dispatch,
    profile = {}
  } = props

  const {
    first_name,
    last_name,
    email,
    card_number,
    tag_number,
    beacon,
    preferentialAccess,
    lng
  } = profile


  const organization = profile.company.name
  const groups = profile.groups.map(g => g.name).join(', ')
  const team = profile.team.map(g => g.name).join(', ')

  const theme = useTheme()
  const navigate = useNavigate()
  const isLarge = useMediaQuery(theme.breakpoints.up('md'))
  const { t } = useTranslation()

  const [openVehicles, setOpenVehicles] = useState(false)
  const [loading, setLoading] = useState(false)
  const [loadingModal, setLoadingModal] = useState(false)
  const [vehicles, setVehicles] = useState([])
  const [staticVehicles, setStaticVehicles] = useState([])

  const fetchVehicles = useCallback(({ fromModal }) => {
    if (!fromModal) setLoading(true)
    api.get('/vehicles')
      .then(({ data }) => {
        iteration = 0;
        if (!data.success) return
        setVehicles(data.vehicles)
        setStaticVehicles(data.vehicles)
        setOpenVehicles(false)
      })
      .catch(error => {
        if (error?.response?.status === 401) eval401Error(navigate, () => fetchVehicles({ fromModal }))
        else if (error.response) console.log(error.response.data.errors)
        else console.log(error.message)
      })
      .finally(() => {
        setLoading(false)
        setLoadingModal(false)
      })
  }, [eval401Error, navigate])

  useEffect(() => {
    fetchVehicles({})
  }, [fetchVehicles])

  const updateVehicle = async vehicle => {
    const { id } = vehicle
    const data = { vehicle }
    try {
      await api.put(`/vehicle/${id}`, data)
      return iteration = 0
    } catch (error) {
      if (error?.response?.status === 401)
        eval401Error(navigate, () => updateVehicle(vehicle))
      else if (error.response)
        console.log(error.response.data.errors)
      else
        console.log(error.message)
    }
  }

  const createVehicle = async vehicle => {
    const data = { vehicle }
    try {
      await api.post(`/vehicle`, data)
      return iteration = 0
    } catch (error) {
      if (error?.response?.status === 401)
        eval401Error(navigate, () => createVehicle(vehicle))
      else if (error.response)
        console.log(error.response.data.errors)
      else
        console.log(error.message)
    }
  }

  const deleteVehicle = async vehicle => {
    const { id } = vehicle
    try {
      const resp = await api.delete(`/vehicle/${id}`)
      if (!resp?.data?.success) {
        dispatch({
          type: Types.SET_SNACKBAR_DATA,
          payload: {
            open: true,
            message: resp.data.message,
            severity: 'error',
            autoHideDuration: 2000
          }
        })
      }
      return iteration = 0
    } catch (error) {
      if (error?.response?.status === 401)
        eval401Error(navigate, () => deleteVehicle(vehicle))
      else if (error.response)
        console.log(error.response.data.errors)
      else
        console.log(error.message)
    }
  }

  const onSaveVehicles = (async () => {
    setLoadingModal(true)
    const _vehicles = vehicles.filter(v => v.license_plate.trim())

    for (let v of _vehicles) {
      if (!v.id) await createVehicle(v)
      else if (v.deleted) await deleteVehicle(v)
      else if (v.edited) await updateVehicle(v)
    }

    fetchVehicles({ fromModal: true })
  })

  const addNewVehicle = () => {
    const _vehicles = [...vehicles]
    _vehicles.push({ symbol: Symbol(), license_plate: '' })
    setVehicles(_vehicles)
  }

  const removeVehicle = (e, vehicle) => {
    vehicle.deleted = true
    setVehicles([...vehicles])
  }

  const onChangeLicensePlate = (value, vehicle) => {
    vehicle.license_plate = value
    vehicle.edited = true
    setVehicles([...vehicles])
  }

  const backdrop = useCallback(() => {
    return (
      <Backdrop
        sx={{
          color: '#fff',
          zIndex: theme => theme.zIndex.drawer + 1
        }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    )
  }, [loading])

  const getStaticVehicles = useCallback(
    () => staticVehicles.length ? staticVehicles.map(v => v.license_plate).join(', ') : '',
    [staticVehicles]
  )

  return (
    <Layout title={t('my information')}>
      <div className="Information general-scroll">
        <div className="information-inner">
          <div className='item-wrapper'>
            <h2>{t('name')}</h2>
            <h3>{first_name}</h3>
          </div>
          <Divider />
          <div className='item-wrapper'>
            <h2>{t('last name')}</h2>
            <h3>{last_name}</h3>
          </div>
          <Divider />
          <div className='item-wrapper'>
            <h2>{t('email')}</h2>
            <h3>{email}</h3>
          </div>
          <Divider />
          <div className='item-wrapper'>
            <IconButton
              className="item-icon"
              size="large"
              edge="start"
              aria-label="info"
              onClick={() => setOpenVehicles(true)}
            >
              <EditIcon />
            </IconButton>
            <h2>{t('vehicles')}</h2>
            <h3>{getStaticVehicles()}</h3>
          </div>
          {card_number && <>
            <Divider />
            <div className='item-wrapper'>
              <h2>{t('card')}</h2>
              <h3>{card_number}</h3>
            </div>
          </>
          }
          {tag_number && <>
            <Divider />
            <div className='item-wrapper'>
              <h2>{t('tag')}</h2>
              <h3>{tag_number}</h3>
            </div>
          </>
          }
          <Divider />
          <div className='item-wrapper'>
            <h2>{t('beacon')}</h2>
            <h3>{beacon}</h3>
          </div>
          <Divider />
          <div className='item-wrapper'>
            <h2>{t('organization')}</h2>
            <h3>{organization}</h3>
          </div>
          <Divider />
          <div className='item-wrapper'>
            <h2>{t('team')}</h2>
            <h3>{team}</h3>
          </div>
          <Divider />
          <div className='item-wrapper'>
            <h2>{t('parking group')}</h2>
            <h3>{groups}</h3>
          </div>
          <Divider />
          <div className='item-wrapper'>
            <HtmlTooltip
              arrow
              placement={isLarge ? "right" : "left"}
              title={t('preferential access')}
              message={t('condition of preferential access due to disability, pregnancy or special condition')}>
              <IconButton
                className="item-icon"
                size="large"
                edge="start"
                aria-label="info"
              >
                <InfoOutlinedIcon />
              </IconButton>
            </HtmlTooltip>
            <h2>{t('preferential access')}</h2>
            <h3>{t(preferentialAccess.toString())}</h3>
          </div>
          <Divider />
          <div className='item-wrapper'>
            <h2>{t('language')}</h2>
            <h3>{lng}</h3>
          </div>
        </div>
      </div>
      <VehiclesModal
        loading={loadingModal}
        open={openVehicles}
        vehicles={vehicles}
        setOpen={setOpenVehicles}
        removeVehicle={removeVehicle}
        onSaveVehicles={onSaveVehicles}
        onChangeLicensePlate={onChangeLicensePlate}
        addNewVehicle={addNewVehicle}
      />
      {backdrop()}
    </Layout>
  );
}

const mapStateToProps = state => {
  return {
    profile: state.profile
  }
}

const mapDispatchToProps = {
  eval401Error,
  dispatch
}

export default connect(mapStateToProps, mapDispatchToProps)(Information)
