import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'

import {
  Button,
  Stack,
  Select,
  MenuItem,
  Divider,
  Alert
} from '@mui/material'

import { Types } from '../../state/actionTypes'
import Layout from '../../common/components/Layout/Layout'
import './Settings.sass'
import { api, getBaseUrl } from '../../api/api'

import {
  setLoading,
  defaultSuccess,
  defaultCatch,
  _dispatch as dispatch
} from '../../state/actions'


function Settings(props) {


  const {
    enabledOutlookIntegration,
    graphUserId,
    dispatch,
    lang
  } = props

  let global_baseURL = ''

  const { t } = useTranslation()
  const [lng, setLng] = useState(lang)
  const handleSave = () => {

    dispatch({
      type: Types.SET_LANGUAGE,
      payload: lng
    })

    dispatch({
      type: Types.SET_SNACKBAR_DATA,
      payload: {
        open: true,
        message: t('saved'),
        severity: 'success',
        autoHideDuration: 1000
      }
    })
  }

  const messageListener = event => {
    const { origin } = event
    const url = global_baseURL.replace('/api', '').replace('https://', '')
    if (!origin.includes(url)) return
    window.global_tab?.close()
    dispatch({ type: Types.SET_TRUE_GRAPH_USER_ID })
    window.removeEventListener("message", messageListener)
  }

  function loadAuthorize(url) {
    setLoading(true)
    api.get(url)
      .then(({ data }) => {
        if (!data.success) return
        window.global_tab = window.open(data.url)
        setLoading(false)
        dispatch({ type: Types.SET_TRUE_GRAPH_USER_ID })
      })
      .catch(error => defaultCatch(error, loadAuthorize))
  }

  function handleAuthorize() {
    const route = `/graph/authorize`
    const baseURL = getBaseUrl()
    window.addEventListener("message", messageListener, false)
    loadAuthorize(`${baseURL}${route}`)
  }

  return (
    <Layout title={t('settings')}>
      <Stack spacing={5} className="Settings horizontal">
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
        >
          <label>{t('language')}</label>
          <Select
            className="filled-select"
            variant="filled"
            labelId="lang-label"
            value={lng}
            onChange={event => setLng(event.target.value)}
          >
            <MenuItem value='es'> {t('spanish')}</MenuItem>
            <MenuItem value='en'> {t('english')}</MenuItem>
          </Select>
        </Stack>
      </Stack>
      {
        enabledOutlookIntegration &&
        <Stack spacing={5} className="Settings horizontal">
          <Divider />
          <Stack
            justifyContent="space-between"
            spacing={2}
          >
            <label>{t('Synchronize your calendar with outlook')}</label>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={2}
            >
              <p>{t('Authorize the synchronization of your calendar with Outlook, by doing this you will allow the reservations you create to be reflected in your calendar.')}</p>
              {graphUserId ?
                <Alert severity="info" icon={false} >{t('You have successfully synced your calendar.')}</Alert> :
                <Button
                  sx={{ width: '170px' }}
                  variant="contained"
                  onClick={handleAuthorize}
                >
                  {t('Authorize')}
                </Button>
              }
            </Stack>
          </Stack>
        </Stack>
      }
      <div className='save-setting-button-wrapper'>
        <Button
          className='save-setting-button'
          sx={{ width: '272px' }}
          variant="contained"
          onClick={handleSave}
        >
          {t('save changes')}
        </Button>
      </div>
    </Layout>
  )
}


const mapDispatchToProps = {
  dispatch,
  setLoading,
  defaultSuccess,
  defaultCatch
}

const mapStateToProps = state => {
  return {
    lang: state.setting.lang,
    enabledOutlookIntegration: state.profile.company.enabled_outlook_integration,
    graphUserId: state.profile.graph_user_id,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Settings)