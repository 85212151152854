import React from 'react'
import IconButton from '@mui/material/IconButton'
import Divider from '@mui/material/Divider'
import { useNavigate } from 'react-router-dom'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import {
  Close as CloseIcon,
  ArrowBackIos as ArrowBackIosIcon
} from '@mui/icons-material'


import './LayoutCreate.sass'

function LayoutCreate(props) {
  const navigate = useNavigate()
  const theme = useTheme()
  const isLarge = useMediaQuery(theme.breakpoints.up('md'))
  const defaultGoBack = () => navigate(-1)

  const {
    children,
    title = 'Title',
    goBack = defaultGoBack,
    headerClassName = ""
  } = props;

  return (
    <div className='layout-create'>
      <header className={headerClassName}>
        <IconButton
          className="close-header"
          size="large"
          aria-label="account of current user"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={goBack}
          color="inherit"
        >
          {isLarge ? <CloseIcon /> : <ArrowBackIosIcon />}
        </IconButton>
        <h1 className='title-header'>{title}</h1>
      </header>
      <Divider className="hr-header" />
      <div>
        {children}
      </div>
    </div>
  );
}


export default LayoutCreate
