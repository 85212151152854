import React from 'react'
import './StatusChip.sass'
import { useTranslation } from 'react-i18next'

import { Chip } from '@mui/material'

function StatusChip({ status, label = '' }) {
  const { t } = useTranslation()

  return (
    <Chip className={`StatusChip ${status}`} label={label ? label : t(status.toLowerCase())} size="small" />
  )
}

export default StatusChip