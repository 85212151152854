import React, { useState, useEffect } from 'react';
import { Button, TextField, Box } from '@mui/material';
import {Add as AddIcon, Remove as RemoveIcon } from '@mui/icons-material';

import './Counter.sass'

const Counter = ({ initialValue = 1, min = 0, max = Infinity, onChange, isDisabled = false }) => {
  const [count, setCount] = useState(initialValue);

  useEffect(() => {
    setCount(initialValue);
  }, [initialValue]);

  const handleInputChange = (event) => {
    const value = event.target.value;
    if (value === '' || (/^\d+$/.test(value) && parseInt(value) >= min && parseInt(value) <= max)) {
      setCount(value);
      if (onChange) {
        onChange(parseInt(value));
      }
    }
  };

  const handleBlur = () => {
    if (count === '') {
      setCount(min);
      if (onChange) {
        onChange(min);
      }
    }
  };

  const handleIncrement = () => {
    setCount((prevCount) => {
      const newValue = Math.min(max, prevCount + 1);
      if (onChange) {
        onChange(newValue);
      }
      return newValue;
    });
  };

  const handleDecrement = () => {
    setCount((prevCount) => {
      const newValue = Math.max(min, prevCount - 1);
      if (onChange) {
        onChange(newValue);
      }
      return newValue;
    });
  };

  return (
    <Box className='counter-container'>
      <Button
        className='control-button'
        onClick={handleDecrement}
        variant="contained"
        size="small"
        disabled={count <= min || isDisabled}
        sx={{ minWidth: '32px' }}
      >
        <RemoveIcon fontSize="small" />
      </Button>
      <TextField
        className='counter-input'
        value={count}
        onChange={handleInputChange}
        onBlur={handleBlur}
        size="small"
        inputProps={{
          min,
          max,
          style: { textAlign: 'center' },
        }}
        sx={{ maxWidth: '40px' }}
      />
      <Button
        className='control-button'
        onClick={handleIncrement}
        variant="contained"
        size="small"
        disabled={count >= max || isDisabled}
        sx={{ minWidth: '32px' }}
      >
        <AddIcon fontSize="small" />
      </Button>
    </Box>
  );
};

export default Counter;
