import { DateTime } from 'luxon'

const DISTANCE_TOLERANCE_KM_MOVIL = 0.5//KM
const DISTANCE_TOLERANCE_KM_WEB = 15//KM 

const MAX_401_ATTEMPTS = 3
const MAX_NOTES_LENGTH = 200
const MAX_MULTI_DAY_SELECTED_PARKING = 5
const REGEX_HTTP_URL = /(https?:\/\/[^\s]+)/g
const REGEX_ENDLINE = /\n/g
// eslint-disable-next-line no-useless-escape
const REGEX_EMAIL = /[a-zA-Z0-9\-]+[\._]?[a-zA-Z0-9]+[@][\w\-]+[.]\w{2,3}/


const DEV_ROOT_URL = 'corporatedev.parso.cr'
const PRO_ROOT_URL = 'corporateparking.parso.cr'

const GOOGLE_MAP_URL = 'https://www.google.com/maps'
const DEFAULT_FLOOR_IMAGE = { url: 'static_map.png', size: [] }
const NO_IMAGE = 'no_image_available.png'
const IMAGE_BOUNDS = [[0, 0], [1000, 1000]]
const CENTER = [0, 0]
const MIN_ZOOM = -3
const DEFAULT_ZOOM = -2
const INITIAL_OFFSET = 4
const DEFAULT_OFFSET_MIN_RESERVATION_MINUTES = 5
const ONE_AM = DateTime.local().set({ hour: 1, minute: 0, second: 0 }).toJSDate() // Js Date 
const EIGHT_AM = DateTime.local().set({ hour: 8, minute: 0, second: 0 }).toJSDate() // Js Date 
const FIVE_PM = DateTime.local().set({ hour: 17, minute: 0, second: 0 }).toJSDate() // Js Date 
const END_DAY = DateTime.local().set({ hour: 23, minute: 59, second: 0 }).toJSDate() // Js Date 
const ELEVEN_PM = DateTime.local().set({ hour: 23, minute: 0, second: 0 }).toJSDate() // Js Date 

const MORNING = 'MORNING'
const AFTERNOON = 'AFTERNOON'
const ALLDAY = 'ALLDAY'



const NOT_AVAILABLE = 'NOT_AVAILABLE'
const ALL_DAY = 'ALL_DAY'
const ALL_MORNING = 'ALL_MORNING'
const PARTIAL_MORNING = 'PARTIAL_MORNING'
const ALL_AFTERNOON = 'ALL_AFTERNOON'
const PARTIAL_AFTERNOON = 'PARTIAL_AFTERNOON'


const PARKING_DESK_RESERVATION = 'ParkingDeskReservation'
const PARKING_ROOM_RESERVATION = 'ParkingRoomReservation'
const PARKING_RESERVATION = 'ParkingReservation'
const DESK_RESERVATION = 'Workplace::DeskReservation'
const ROOM_RESERVATION = 'Workplace::RoomReservation'
const PARKING_ASSIGNATION = 'PARKING_ASSIGNATION'
const DESK_ASSIGNATION = 'DESK_ASSIGNATION'
const VISIT_REGISTER = 'VisitRegister'
const CAFETERIA_RESERVATION = 'CAFETERIA_RESERVATION'

const PENDING = 'PENDING'
const APPROVED = 'APPROVED'
const REJECTED = 'REJECTED'
const ABSENCE = 'ABSENCE'
const DISABLED = 'DISABLED'
const ENABLED = 'ENABLED'
const RELEASED = 'RELEASED'

const WARNING = 'WARNING'
const INFO = 'INFO'
const ERROR = 'ERROR'
const UNSUPPORTED = 'UNSUPPORTED'
const OK = 'OK'

const SOFT = 'SOFT'
const HARD = 'HARD'

const LESSER = 'LESSER'
const GREATER = 'GREATER'
const EQUAL = 'EQUAL'

const VISIT = 'VISIT'
const IMPORT = 'IMPORT'
const ENABLED_PD = ['demo', 'astrazeneca', 'ibk', 'ibk2', 'public'] // Tenants with parking + desk 
const ENABLED_PR = ['demo', 'ibk', 'ibk2', 'public'] // Tenants with parking + rooms

const ISO_DATE_SIZE_WITHOUT_TIME_ZONE = 23

const DEFAULT_GUEST = {
  first_name: '',
  last_name_one: '',
  email: '',
  reason: '',
  identification: '',
  invitation_date: '',
  invitation_time: '',
  comes_from: '',
  building_id: '',
  company_id: '',
}

const DEFAULT_ROOM_FILTERS = {
  site: -1,
  building: -1,
  floor: -1,
  capacity: 1,
  amenities: [],
  filtered: false
}


const HOURS = [
  '12 AM',
  '1 AM',
  '2 AM',
  '3 AM',
  '4 AM',
  '5 AM',
  '6 AM',
  '7 AM',
  '8 AM',
  '9 AM',
  '10 AM',
  '11 AM',
  '12 PM',
  '1 PM',
  '2 PM',
  '3 PM',
  '4 PM',
  '5 PM',
  '6 PM',
  '7 PM',
  '8 PM',
  '9 PM',
  '10 PM',
  '11 PM'
]

const VALID_HEIGHT_BY_HOUR = [
  0,
  15,
  30,
  45,
  60,
  75,
  90,
  105,
  120,
  135,
  150,
  165,
  180,
  195,
  210,
  225,
  240,
  255,
  270,
  285,
  300,
  315,
  330,
  345,
  360,
  375,
  390,
  405,
  420,
  435,
  450,
  465,
  480,
  495,
  510,
  525,
  540,
  555,
  570,
  585,
  600,
  615,
  630,
  645,
  660,
  675,
  690,
  705,
  720,
  735,
  750,
  765,
  780,
  795,
  810,
  825,
  840,
  855,
  870,
  885,
  900,
  915,
  930,
  945,
  960,
  975,
  990,
  1005,
  1020,
  1035,
  1050,
  1065,
  1080,
  1095,
  1110,
  1125,
  1140,
  1155,
  1170,
  1185,
  1200,
  1215,
  1230,
  1245,
  1260,
  1275,
  1290,
  1305,
  1320,
  1335,
  1350,
  1365,
  1380,
  1395,
  1410,
  1425,
  1440
]

const ONE_DAY = 24
const ONE_HOUR = 60
const ONE_MINUTE = ONE_HOUR / 60
const ONE_DAY_BY_HOUR = ONE_HOUR * ONE_DAY
const ONE_DAY_BY_HOUR_AVAILABLE = ONE_DAY_BY_HOUR - ONE_MINUTE
const FIFTEEN_MINUTES = ONE_HOUR / 4

const FORM = 'FORM'
const SUCCESS = 'SUCCESS'

export {
  DISTANCE_TOLERANCE_KM_MOVIL,
  DISTANCE_TOLERANCE_KM_WEB,
  DEFAULT_OFFSET_MIN_RESERVATION_MINUTES,
  ONE_DAY_BY_HOUR_AVAILABLE,
  ISO_DATE_SIZE_WITHOUT_TIME_ZONE,
  MAX_MULTI_DAY_SELECTED_PARKING,
  PARKING_DESK_RESERVATION,
  PARKING_ROOM_RESERVATION,
  VALID_HEIGHT_BY_HOUR,
  PARKING_RESERVATION,
  DESK_RESERVATION,
  ROOM_RESERVATION,
  VISIT_REGISTER,
  CAFETERIA_RESERVATION,
  MAX_NOTES_LENGTH,
  DEFAULT_FLOOR_IMAGE,
  NO_IMAGE,
  MAX_401_ATTEMPTS,
  GOOGLE_MAP_URL,
  DEFAULT_ROOM_FILTERS,
  REGEX_HTTP_URL,
  PARKING_ASSIGNATION,
  DESK_ASSIGNATION,
  REGEX_ENDLINE,
  DEFAULT_GUEST,
  IMAGE_BOUNDS,
  DEV_ROOT_URL,
  PRO_ROOT_URL,
  ENABLED,
  ENABLED_PD,
  ENABLED_PR,
  ELEVEN_PM,
  INITIAL_OFFSET,
  DEFAULT_ZOOM,
  REGEX_EMAIL,
  UNSUPPORTED,
  NOT_AVAILABLE,
  ALL_DAY,
  ALL_MORNING,
  PARTIAL_MORNING,
  ALL_AFTERNOON,
  PARTIAL_AFTERNOON,
  HOURS,
  SUCCESS,
  FORM,
  ONE_DAY,
  ONE_HOUR,
  ONE_DAY_BY_HOUR,
  FIFTEEN_MINUTES,
  PENDING,
  APPROVED,
  REJECTED,
  ABSENCE,
  DISABLED,
  RELEASED,
  EIGHT_AM,
  MIN_ZOOM,
  ONE_AM,
  LESSER,
  GREATER,
  EQUAL,
  MORNING,
  AFTERNOON,
  ALLDAY,
  ONE_MINUTE,
  FIVE_PM,
  WARNING,
  END_DAY,
  CENTER,
  IMPORT,
  VISIT,
  ERROR,
  INFO,
  SOFT,
  HARD,
  OK
}