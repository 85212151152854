import React from 'react'

import {
  Button,
  IconButton,
  Dialog,
  DialogTitle,
  DialogActions,
  Stack,
  Box
} from '@mui/material'

import {
  Close as CloseIcon,
  InfoOutlined as InfoOutlinedIcon
} from '@mui/icons-material'

import { useTranslation } from 'react-i18next'

import './AlertDialog.sass'

const AlertDialog = props => {
  const {
    open,
    handleClose,
    onClickLeftButton,
    onClickRightButton,
  } = props

  const { t } = useTranslation()

  return (
    <Dialog
      className="alert-dialog"
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle className="alert-dialog-title">
        <Stack
          alignItems={'center'}
          spacing={'4px'}
        >
          <Box className='close-button-container'>
            <IconButton
              onClick={handleClose}
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <InfoOutlinedIcon className='info-icon' />
          <h2>{t('Would you like to book the room all day?')}</h2>
        </Stack>
      </DialogTitle>
      <DialogActions className='alert-dialog-actions'>
        <Button
          onClick={onClickLeftButton}
          variant='outlined'
        >
          {t('Per Hours')}
        </Button>
        <Button
          onClick={onClickRightButton}
          variant='contained'
        >
          {t('All day')}
        </Button>
      </DialogActions>
    </Dialog>

  )
}

export default AlertDialog;