import React, { useState, useEffect, useCallback } from 'react'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import CircularProgress from '@mui/material/CircularProgress'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import { Box, Chip, FormControl, Grid, Stack, useMediaQuery } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { api } from '../../../api/api'
import {
  _dispatch as dispatch,
  defaultSuccess,
  defaultCatch
} from '../../../state/actions'
import { connect } from 'react-redux'
import { debounce, defaultFunction } from '../../utilities/utilities'
import './EmailAutoComplete.sass'
import { useTheme } from '@emotion/react'

import {
  REGEX_EMAIL
} from '../../../variables'

import {
  Info as InfoIcon,
} from '@mui/icons-material'
import { Types } from '../../../state/actionTypes'

function EmailAutoComplete(props) {
  const {
    dispatch,
    defaultSuccess,
    defaultCatch,
    disabled = false,
    direction = "row",
    justifyContent = "space-between",
    alignItems = "center",
    setEmails = defaultFunction,
    emails = [],
    sx = {}
  } = props

  const { t } = useTranslation()
  const theme = useTheme()
  const isLarge = useMediaQuery(theme.breakpoints.up('md'))

  const navigate = useNavigate()
  const [inputValue, setInputValue] = useState('')
  const [options, setOptions] = useState([])
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const onSearch = useCallback(inputValue => {
    if (inputValue.length === 0) {
      setOptions([])
      return
    }

    setLoading(true)
    api.post(`employees/emails`, { prefix: inputValue })
      .then(defaultSuccess)
      .then(({ data }) => {
        if (!data.success) return
        const { users = [] } = data
        setOptions(users.map(u => ({ fullName: `${u.first_name} ${u.last_name}`, label: u.email })))
      })
      .catch(error => defaultCatch(error, navigate))
      .finally(() => setLoading(false))
  }, [defaultCatch, defaultSuccess, navigate])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onEfficientSearch = useCallback(
    debounce((nextValue) => onSearch(nextValue), 1000),
    [onSearch]  // Add onSearch as a dependency for React to detect changes correctly
  );

  const onChangeInput = useCallback((_, nextInputValue) => {
    setErrorMessage('')
    setInputValue(nextInputValue);
    onEfficientSearch(nextInputValue);  // This will use the debounced onSearch
  }, [onEfficientSearch])

  // Close autocomplete when input is cleared
  useEffect(() => {
    if (inputValue === '') setOpen(false)
  }, [inputValue])


  const getErrorMessages = useCallback(() => {
    if (errorMessage) {
      return (
        <p className="attendees-error-message">
          {errorMessage}
        </p>
      )
    }
  }, [errorMessage])

  const onKeyPress = useCallback((event, value) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      if (REGEX_EMAIL.test(value)) {
        if (value.length > 0 && emails.includes(value)) {
          setErrorMessage(t('The email address is already in the list.'))
        } else {
          setEmails([...emails, value])
          setInputValue('')
          dispatch({
            type: Types.SET_SNACKBAR_DATA,
            payload: {
              open: true,
              message: t('Attendee added'),
              severity: 'success',
              autoHideDuration: 6000,
              horizontal: 'right'
            }
          })
        }
      } else if (value.length > 0) {
        setErrorMessage(t('The email address entered is not valid.'))
      }
    }
  }, [dispatch, emails, setEmails, t])

  const getAutoComplete = useCallback(() => (
    <Grid item xs={12} md>
      <Stack
        className='Field'
        gap={2}
        sx={sx}
        direction={direction}
        justifyContent={justifyContent}
        alignItems={alignItems}
      >
        <FormControl fullWidth>
          <Autocomplete
            size='small'
            noOptionsText={t('no options')}
            filterOptions={options => options}
            autoSelect={false}
            freeSolo={true}
            fullWidth
            disabled={disabled}
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            onInputChange={onChangeInput}
            inputValue={inputValue}
            options={options}
            loading={loading}
            renderOption={(props, option) => {
              const { fullName, label: email } = option
              return (
                <Box
                  component="li"
                  {...props}
                >
                  <Stack direction='row' gap={2} alignItems="center">
                    <AccountCircleIcon color="info" fontSize="large" />
                    <Stack gap={0} className='attendees-option'>
                      <label>{fullName}</label>
                      <span>{email}</span>
                    </Stack>
                  </Stack>
                </Box>
              )
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="filled"
                placeholder={isLarge ? '' : t('Meeting attendees (Optional)')}
                onKeyPress={event => onKeyPress(event, inputValue)}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {loading ? <CircularProgress color="inherit" size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
              />
            )}
          />
        </FormControl>
      </Stack>
    </Grid>
  ), [sx, direction, justifyContent, alignItems, t, disabled, open, onChangeInput, inputValue, options, loading, isLarge, onKeyPress])

  return (
    <Grid item xs={12} md={7}>
      <label
        className={`pre-label-form`}>
        {t('Meeting attendees (Optional)')}
      </label>
      <Grid
        container
        spacing={isLarge ? 0 : 5}
        className="form-input-group"
      >
        {getAutoComplete()}
      </Grid>
      {getErrorMessages()}
      <Stack gap={3}>
        <Stack
          className='attendees-info'
          direction="row"
          alignItems="center"
          gap={1}
        >
          <InfoIcon />
          {t('When you add guests, we will send them an email with the details of the meeting.')}
        </Stack>
        {
          emails.length !== 0 &&
          <Grid item xs={12} md={7}>
            <Grid container columns={2} gap={1}>
              {emails.map((attendee, index) =>
                <Chip
                  className='attendee-chip'
                  key={index}
                  label={attendee}
                  onDelete={() => setEmails(emails.filter((_, i) => i !== index))}
                  color='primary'
                  variant='outlined'
                />
              )}
            </Grid>
          </Grid>
        }
      </Stack>
    </Grid>
  )
}

const mapDispatchToProps = {
  dispatch,
  defaultSuccess,
  defaultCatch
}

export default connect(null, mapDispatchToProps)(EmailAutoComplete)