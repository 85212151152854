import React, { useEffect, useState } from 'react'
import { api } from '../../../api/api'
import { connect } from "react-redux"

import {
  setLoading,
  defaultSuccess,
  defaultCatch,
} from '../../../state/actions'

import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  IconButton,
  Box,
  Stack,
  CardMedia,
  Chip
} from '@mui/material'

import {
  West as WestIcon,
} from '@mui/icons-material'

import { useTranslation } from 'react-i18next'
import { defaultFunction, getAmenitieImage, getLocalitation } from '../../utilities/utilities'
import { useNavigate } from 'react-router-dom'

import './RoomDetailsModal.sass'
import { NOT_AVAILABLE } from '../../../variables'

const RoomDetailsModal = (props) => {
  const {
    room = {},
    open = false,
    handleClose = defaultFunction,
    setRoomDetailsModalOpen,
    onConfirmRoom,
    setLoading,
    defaultSuccess,
    defaultCatch
  } = props

  const { t } = useTranslation()
  const navigate = useNavigate()


  const [roomImage, setRoomImage] = useState()

  const fetchRoomImage = () => {
    const image = room?.photos[room?.photos?.length - 1]?.url.replace(/^\/api/, '')
    if (!image) return setRoomImage()

    setLoading(true)
    api.get(image, { responseType: "blob" })
      .then(defaultSuccess)
      .then(({ data }) => setRoomImage(URL.createObjectURL(data)))
      .catch(error => defaultCatch(error, navigate, () => fetchRoomImage()))
  }

  useEffect(() => {
    if (open) fetchRoomImage()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])


  const getAmenities = (amenities = []) => {
    return amenities.map(a =>
      <Chip
        key={a}
        variant='outlined'
        icon={getAmenitieImage(a, t)}
        label={`${getAmenitieImage(a, t).props.title}`}
        className='amenity-chip'
      />
    )
  }

  const handleConfirmationRoom = () => {
    onConfirmRoom()
    setRoomDetailsModalOpen(false)
  }

  return (
    <Dialog
      className="room-details"
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogContent className="dialog-detail-content">
        <Stack rowGap={'24px'}>
          <Box className='room-details-header'>
            <IconButton
              onClick={() => {
                handleClose()
                setRoomImage(undefined)
              }}
              className='back-button'
            >
              <WestIcon />
            </IconButton>
            <h1>{t('Room details')}</h1>
          </Box>
          {roomImage ?
            <CardMedia
              className='room-image'
              component='img'
              image={roomImage}
              alt='room image'
            />
            :
            <Box className='image-placeholder'></Box>
          }
          <Box className='room-details-info'>
            <h2>{room?.name}</h2>
            <ul>
              <li>{`${t('Location')}:  ${getLocalitation(room)}`}</li>
              <li>{t('capacity')}: {room?.capacity} {t('seats')}</li>
            </ul>
          </Box>
          {room?.amenities?.length !== 0 &&
            <Stack className='amenities-list' rowGap={'8px'}>
              {`${t('amenities')}:`}
              <Stack direction="row" flexWrap="wrap" gap={'8px'}>
                {getAmenities(room?.amenities)}
              </Stack>
            </Stack>
          }
        </Stack>
        {
          room.label !== NOT_AVAILABLE &&
          <DialogActions>
            <Button
              className='book-room-button'
              variant='contained'
              onClick={handleConfirmationRoom}
            >
              {t('Book room')}
            </Button>
          </DialogActions>
        }
      </DialogContent>
    </Dialog>
  );
}

const mapDispatchToProps = {
  setLoading,
  defaultSuccess,
  defaultCatch
}

export default connect(null, mapDispatchToProps)(RoomDetailsModal)