const ParsoIco = props => {
  const {
    className = '',
    style = {},
    width = 7
  } = props

  return (
    <svg
      className={className}
      style={style}
      width={width}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 76 104">
      <path
        fill="#fff"
        d="m37.8 66 14-5 15.6-46.7a37.8 37.8 0 0 0-59.1 0l15.5 46.6 14 5Zm0-47a14.2 14.2 0 1 1 .1 28.5A14.2 14.2 0 0 1 38 19ZM7.7 15.1a38 38 0 0 0-4.4 38.7l19.6 7L7.7 15Zm15.5 46.6-19.6-7c2.7 6.9 6.2 13.5 10.3 19.6L26.1 70l-2.9-8.3Zm49.3-8A37.7 37.7 0 0 0 68.1 15L52.8 60.7l19.7-7Zm-58 21.4a211.5 211.5 0 0 0 23 28.6l-11-32.8-12 4.2Zm34.4-5.2 2.7-8L39 66.5l9.9 3.5Zm-22.1 0 9.9-3.5L24.2 62l2.6 7.9Zm.2.8L37.8 103l10.8-32.4-10.8-3.9-10.8 4Zm11.5 33c8.4-8.8 16.1-18.4 22.9-28.5l-12-4.3-11 32.8Zm11.2-33.5 12.1 4.2a108 108 0 0 0 10.4-19.6l-19.7 7-2.8 8.4Z" />
    </svg>
  )
}

export default ParsoIco