import PickersDay from '@mui/lab/PickersDay'
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { Menu } from '@mui/material'
import {
  Switch,
  styled,
  Box,
} from '@mui/material'

import NoRows from '../components/Svgs/NoRows'

const ParsoSwitch = styled(Switch)(({ theme, checked, leftlabel, rightlabel }) => ({
  padding: 0,
  width: 235,
  height: 40,
  fontSize: 13,
  '& .MuiSwitch-switchBase': {
    padding: 2
  },
  '& .MuiSwitch-track': {
    borderRadius: 20,
    backgroundColor: '#EFEFEF',
    '&:before, &:after': {
      content: '""',
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      width: 113,
      height: 32,
    },
    '&:before': {
      content: leftlabel,
      left: 12,
      marginTop: 10
    },
    '&:after': {
      content: rightlabel,
      right: 12,
      marginTop: 10
    }
  },
  '& .Mui-checked+.MuiSwitch-track': {
    backgroundColor: '#EFEFEF'
  },
  '& .Mui-checked': {
    transform: 'translateX(115px)'
  },
  '& .MuiSwitch-thumb': {
    padding: '10px 0 0 0',
    boxSizing: 'border-box',
    boxShadow: 'none',
    borderRadius: 20,
    background: 'white',
    width: 113,
    height: 32,
    margin: 2,
    '&:before, &:after': {
      content: '""',
      transform: 'translateY(-50%)',
      width: 113,
      height: 32,
      color: 'black'
    },
    '&:before': {
      content: checked ? rightlabel : leftlabel,
    },
  },
}))

const CustomPickersDay = styled(PickersDay, {
  shouldForwardProp: (prop) => prop !== 'selected'
})(({ theme, selected, unavailable }) => ({
  margin: '0 2px',
  borderTopLeftRadius: '50%',
  borderBottomLeftRadius: '50%',
  borderTopRightRadius: '50%',
  borderBottomRightRadius: '50%',
  ...(selected && {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    '&:hover, &:focus': {
      backgroundColor: theme.palette.primary.dark
    }
  }),
  ...(unavailable && {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.common.white,
    '&:hover, &:focus': {
      backgroundColor: theme.palette.error.dark
    }
  })
}))

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 14,
  borderRadius: 7,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.mode === 'light' ? 'rgba(255, 255, 255, 0.5)' : 'white',
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 7,
    backgroundColor: theme.palette.mode === 'light' ? 'white' : '#308fe8',
  },
}))

const StyledMenu = styled(props => (
  <Menu
    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
    transformOrigin={{ vertical: 'top', horizontal: 'right' }}
    {...props}
  />
))(() => ({
  '& .MuiList-root ': {
    padding: '0px'
  },
  '& .MuiPaper-root': {
    borderRadius: '0 0 4px 4px',
    minWidth: 229,
    color: 'black',
    background: '#F9F9F9',
    boxShadow: '0px 0px 6px rgba(146, 146, 146, 0.5)'
  },
  '& .MuiMenuItem-root:hover': {
    background: '#D8DFF5'
  }
}))

const getNoRowOverlay = (theme, t) => {
  const StyledGridOverlay = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    '& .ant-empty-img-1': {
      fill: theme.palette.mode === 'light' ? '#aeb8c2' : '#262626',
    },
    '& .ant-empty-img-2': {
      fill: theme.palette.mode === 'light' ? '#f5f5f7' : '#595959',
    },
    '& .ant-empty-img-3': {
      fill: theme.palette.mode === 'light' ? '#dce0e6' : '#434343',
    },
    '& .ant-empty-img-4': {
      fill: theme.palette.mode === 'light' ? '#fff' : '#1c1c1c',
    },
    '& .ant-empty-img-5': {
      fillOpacity: theme.palette.mode === 'light' ? '0.8' : '0.08',
      fill: theme.palette.mode === 'light' ? '#f5f5f5' : '#fff',
    },
  }))

  return (
    <StyledGridOverlay>
      <NoRows />
      <Box sx={{ mt: 1 }}>{t('No Rows')}</Box>
    </StyledGridOverlay>
  )
}

export {
  CustomPickersDay,
  BorderLinearProgress,
  getNoRowOverlay,
  StyledMenu,
  ParsoSwitch
}
