import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { connect } from 'react-redux'

import { blobToBase64, sortItems } from "../../common/utilities/utilities"
import { BorderLinearProgress } from "../../common/utilities/customMaterialUI"
import ParsoIco from "../../common/components/Svgs/ParsoIco"
import { api } from '../../api/api'
import { Types } from '../../state/actionTypes'
import { _dispatch as dispatch, defaultCatch, defaultSuccess } from '../../state/actions'
import './LoadPage.sass'

function LoadPage(props) {
  const {
    dispatch,
    enabledWorkplace,
    enabledVisits,
    enabledDesk,
    enabledRoom,
    defaultCatch,
    defaultSuccess
  } = props

  const navigate = useNavigate()

  const fetchCompanies = () => {
    api.get('/companies')
      .then(defaultSuccess)
      .then(({ data }) => {
        if (!data.success) return
        const companies = data.companies
        dispatch({
          type: Types.SET_COMPANIES,
          payload: companies
        })
        navigate('/')
      })
      .catch(error => defaultCatch(error, navigate, fetchCompanies))
  }

  const fetchSites = () => {
    const params = { desks: enabledDesk || enabledVisits, rooms: enabledRoom }
    api.get('/workplace/sites', { params })
      .then(defaultSuccess)
      .then(({ data }) => {
        if (!data.success) return
        dispatch({
          type: Types.SET_SITES,
          payload: sortItems(data.sites || [])
        })

        if (enabledVisits) fetchCompanies()
        else navigate('/')
      })
      .catch(error => defaultCatch(error, navigate, fetchSites))
  }

  const fetchLogo = () => {
    api.get('logo', { responseType: "blob" })
      .then(({ data }) => {
        if (data.type !== 'text/html') {
          blobToBase64(data)
            .then(base64Image => {
              dispatch({
                type: Types.SET_LOGO,
                payload: { logo: base64Image }
              })
            })
            .catch(error => console.error('Error al convertir Blob a Base64:', error));
        }
        if (enabledWorkplace) fetchSites()
        else if (enabledVisits) fetchCompanies()
        else navigate('/')
      })
  }



  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(fetchLogo, [])

  return (
    <div className="LoadPage">
      <div className="box-loading">
        <ParsoIco width={75} />
        <BorderLinearProgress />
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    enabledWorkplace: state.profile.enabled_workplace,
    enabledRoom: state.profile.enabled_room_reservations,
    enabledDesk: state.profile.company.enabled_desk_reservations,
    enabledVisits: state.profile.enabled_visits,
  }
}

const mapDispatchToProps = {
  dispatch,
  defaultSuccess,
  defaultCatch
}

export default connect(mapStateToProps, mapDispatchToProps)(LoadPage)
