import React, { 
	useEffect, 
	useState,
	useCallback
} from 'react';

import {
	Dialog,
	Card, 
	CardContent, 
	CardMedia, 
	Stack,
	Box,
	Button,
	IconButton
} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import { West } from '@mui/icons-material';

import Counter from '../Counter/Counter';

import { useTranslation } from 'react-i18next';
import './DishDetailModal.sass';

const DishDetailModal = (props) => {
	const { 
		open, 
		onClose, 
		dishImage,
		dish,
		getMealTypeChip,
		priceAfterTaxes,
		orderMinQty,
		orderMaxQty,
		onCloseDishSelectionModal,
		setSelectedDishes,
		selectedDishes,
		onOrderPriceChange,
		onOrderQuantityChange,
		scheduleDayTime,
		currency
	} = props;
	
	const { t } = useTranslation();

	const [dishQuantity, setDishQuantity] = useState(0);

	useEffect(() => {
		if (open) {
		  const dishCount = selectedDishes.filter(d => d.id === dish.id).length;
		  setDishQuantity(dishCount);
		}
	}, [open, dish, selectedDishes]);

	const handleChangeCounter = (value) => {
		const isDifferentSchedule = selectedDishes.some(d => d.schedule_day_time !== scheduleDayTime);

		if (!isDifferentSchedule) {
		  setDishQuantity(value);
		}
	  };

	const handleAddToOrder = useCallback(() => {
		setSelectedDishes(prevDishes => {
		  
		  const isDifferentSchedule = prevDishes.some(d => d.schedule_day_time !== scheduleDayTime);
	  
		  if (!isDifferentSchedule) {
			const newDishes = prevDishes.filter(d => d.id !== dish.id || d.schedule_day_time !== scheduleDayTime);
			
			for (let i = 0; i < dishQuantity; i++) {
			  newDishes.push({ id: dish.id, name: dish.name, final_price: dish.price + dish.taxes, schedule_day_time: scheduleDayTime });
			}
			return newDishes;
		  }
	  
		  return prevDishes;
		});
	  
		if (!selectedDishes.some(d => d.schedule_day_time !== scheduleDayTime)) {
		  const newOrderQuantity = selectedDishes.length + dishQuantity - selectedDishes.filter(d => d.id === dish.id && d.schedule_day_time === scheduleDayTime).length;
		  onOrderQuantityChange(newOrderQuantity);
		
		  const newOrderPrice = selectedDishes.reduce((total, d) => total + ((d.id === dish.id && d.schedule_day_time === scheduleDayTime) ? dishQuantity : 1) * d.final_price, 0);
		  onOrderPriceChange(newOrderPrice);
		}
	  
		onClose();
	}, [dish, dishQuantity, onClose, onOrderQuantityChange, onOrderPriceChange, selectedDishes, setSelectedDishes, scheduleDayTime]);

	return (
		<Dialog
			className='dish-detail-modal'
			open={open}
			onClose={onClose}
			maxWidth='xs'			
		>
			<Card>
				<CardMedia
					className='dish-detail-image'
					component="div"
					image={dishImage}
					alt={dish?.name}
				>
					<Box
						width={'85%'}
						display={'flex'}
						justifyContent={'space-between'}
						alignContent={'flex-start'}
						padding={'24px'}
					>
						<IconButton
							className='dish-detail-close-button'
							onClick={onClose}
						>
							<West />
						</IconButton>
						<IconButton
							className='dish-detail-back-button'
							onClick={() => {
								onClose();
								onCloseDishSelectionModal();
							}}
						>
							<CloseIcon />
						</IconButton>
					</Box>
				</CardMedia>
				<CardContent>
					<Stack
						direction="column"
						justifyContent="center"
						alignItems="left"
						rowGap={'16px'}
					>
						<Stack
							direction="column"
							justifyContent="center"
							alignItems="left"
							rowGap={'8px'}
						>
						<h1>{dish?.name}</h1>
						<p>{dish?.description}</p>
						</Stack>
						<Box
							display={'flex'}
							gap={'6px'}
							flexWrap={'wrap'}
						>
							{getMealTypeChip()}
						</Box>
						<Box
							display={'flex'}
							justifyContent={'left'}
							fontWeight={700}
						>
							<p>{`${currency} ${priceAfterTaxes}`}</p>
						</Box>
						<Box 
							display={'flex'}
							justifyContent={'space-between'}
							alignItems={'center'}
						>
							<Stack
								direction="row"
								justifyContent="center"
								alignItems="center"
								gap={'16px'}
								className='dish-detail-footer'
							>
								<Counter 
									initialValue={dishQuantity}
									min={orderMinQty}
									max={orderMaxQty}
									onChange={handleChangeCounter}
									isDisabled={selectedDishes.some(d => d.schedule_day_time !== scheduleDayTime)}
								/>
								<Button 
									className='dish-detail-add-button'
									variant="contained"
									onClick={handleAddToOrder}
									disabled={dishQuantity === 0 || selectedDishes.some(d => d.schedule_day_time !== scheduleDayTime)}
								>
									{t('add to order')}
								</Button>
							</Stack>
						</Box>
					</Stack>
				</CardContent>
			</Card>
		</Dialog>
	);
};

export default DishDetailModal;
