import { Types } from '../actionTypes'

const initialState = {
  sites: [],
  desksTotal: 0,
  roomsTotal: 0
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case Types.SET_DESK_TOTAL_RESERVATIONS:
      const desksTotal = action.payload
      return { ...state, desksTotal }
    case Types.SET_ROOM_TOTAL_RESERVATIONS:
      const roomsTotal = action.payload
      return { ...state, roomsTotal }
    case Types.SET_SITES:
      const sites = action.payload
      return { ...state, sites }
    case Types.CLEAN_AUTH:
      return initialState
    default:
      return state
  }
}

export default reducer