const Arrow = ({ className = '', style = {}, width = 7 }) => {
  return (
    <svg
      className={className}
      style={style}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 9 16"
      width={width}
    >
      <path fill="var(--secundary)" d="M.413.727a1.319 1.319 0 0 0 0 1.872L5.587 7.75.413 12.903a1.319 1.319 0 0 0 0 1.873c.52.518 1.36.518 1.88 0l6.12-6.095a1.32 1.32 0 0 0 0-1.873L2.293.713C1.787.21.933.21.413.727Z" />
    </svg>
  )
}

export default Arrow