const UploadPillow = ({ className = '', style = {}, width = 7 }) => {
  return (
    <svg
      className={className}
      style={style}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 123 79">
      <path
        fill="#B2BEE5"
        d="M98.7 29.7A37.6 37.6 0 0 0 61.5 0a38 38 0 0 0-33.6 19.9A29.8 29.8 0 0 0 .8 49.2 30 30 0 0 0 31 78.7h66A25 25 0 0 0 122.2 54c0-13-10.4-23.5-23.5-24.4Zm-27 14.6v19.6H51.3V44.3H36.2l23.5-23c1-.9 2.6-.9 3.6 0l23.5 23H71.6Z" />
    </svg>
  )
}

export default UploadPillow