import { useTranslation } from 'react-i18next'
import SupportIntercom from './SupportIntercom'
const hasIntercom = process.env.REACT_APP_INTERCOM_ID

function Support() {
  const { t } = useTranslation()
  return (
    <div className='center-message'>
      <h1>{t('support')}</h1>
      <br />
      {
        hasIntercom ?
          <SupportIntercom /> :
          <p>{t('support chat is only available for production')}</p>
      }
    </div>
  )
}

export default Support